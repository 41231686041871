import React, { useEffect, useMemo } from "react"
import moment from "moment"
import { generateArray } from "@src/scenes/IndividualProfilePage/ProfileInfo/components/IndividualHumanRiskScoreInfoSection/utils"
import HumanRiskTrendTable from "@src/scenes/HumanRiskDashboard/components/TrendingOverTime/HumanRiskScoreTrendOverTime/HumanRiskTrendTable"
import { useDispatch, useSelector } from "react-redux"
import {
  getOrganizationTrendOverTime,
  getTrendOverTimePolicyStats,
  setTimePeriodValue,
  setTrendLoader,
  setTrendOverTime,
} from "@src/services/redux/reputations/actions"
import { TIME_PERIOD_MONTHS } from "@src/scenes/HumanRiskDashboard/constants"
import TimePeriod from "@src/scenes/HumanRiskDashboard/components/TimePeriod/TimePeriod"
import ImageExportWrapper from "@src/components/ImageExportWrapper"
import { getHistoricalActionScores } from "@src/services/apis/reputation"
import { useMonthlyAttackCounts } from "@src/services/apis/reputation/useMonthlyAttackCounts"
import TrendingOverTimeGraphCommon from "@src/components/TrendingOverTimeGraphCommon"
import { selectActiveActions } from "@src/services/redux/reputations/selectors"
import { useTranslation } from "react-i18next"
import { useIsBasicMimecastCustomer } from "@src/utils/useIsBasicMimecastCustomer"

export function TimePeriodSelect() {
  const dispatch = useDispatch()

  const reputations = useSelector((state) => state.get("reputationsReducer"))

  return (
    <TimePeriod
      initialValue={reputations.time_period_value}
      timePeriodHandler={(selected) => {
        dispatch(setTimePeriodValue(selected.value))
        dispatch(setTrendLoader())
      }}
    />
  )
}

export function HumanRiskScoreTrendGraph() {
  const isBasicMimecastCustomer = useIsBasicMimecastCustomer()
  const reputations = useSelector((state) => state.get("reputationsReducer"))
  const policyStats = useMemo(
    () => (!isBasicMimecastCustomer && reputations.policy_stats?.data) || [],
    [isBasicMimecastCustomer, reputations.policy_stats?.data],
  )
  const loader = reputations.trend_loader
  const dispatch = useDispatch()
  const { t } = useTranslation("dashboard")

  const data = useSelector(
    (state) =>
      state.get("reputationsReducer")?.trend_historical_risk_scores || {},
  )

  const risks = useMemo(() => generateArray([...(data?.overall || [])]), [data])

  useEffect(() => {
    if (loader) {
      dispatch(
        getOrganizationTrendOverTime(
          TIME_PERIOD_MONTHS[reputations.time_period_value],
        ),
      )
      dispatch(
        getTrendOverTimePolicyStats(
          TIME_PERIOD_MONTHS[reputations.time_period_value],
        ),
      )
    }
  }, [loader, dispatch, reputations.time_period_value])

  return (
    <TrendingOverTimeGraphCommon
      lineData={risks}
      barData={policyStats}
      loader={loader || reputations.fetchingTrendOverTimePolicyStats}
      xAxisLabel={t("scoreTrendsOverTime.xAxisLabel")}
      yAxisLabel={
        isBasicMimecastCustomer ? "" : t("scoreTrendsOverTime.yAxisLabel")
      }
      xAxisColor={risks?.historical_risks?.length ? "#2064b5" : "#AEAEAE"}
      yAxisColor="#AEAEAE"
      toggleBarsOnLegendClick
      behaviorName={t("humanRiskScoreCard.title")}
    />
  )
}

export function AttackFactorOverTime({ showOrgAttackFactor = false }) {
  const reputations = useSelector((state) => state.get("reputationsReducer"))
  const attackScores = reputations?.trend_historical_risk_scores?.attack
  const { t } = useTranslation("dashboard")

  const activeActions =
    useSelector(selectActiveActions)?.map((action) =>
      action.replaceAll("-", "_"),
    ) || []

  const { attackCounts: barData, loading: monthlyAttacksLoading } =
    useMonthlyAttackCounts({
      months: TIME_PERIOD_MONTHS[reputations.time_period_value] + 1,
      activeActions,
    })

  const lineData = useMemo(
    () => generateArray(attackScores || []),
    [attackScores],
  )

  const loader = reputations.trend_loader || monthlyAttacksLoading

  return (
    <ImageExportWrapper id="AttacksOverTime">
      {showOrgAttackFactor ? (
        <TrendingOverTimeGraphCommon
          lineData={lineData}
          barData={barData}
          loader={loader}
          title={t("orgAttackFactorOverTime.title")}
          yAxisLabel={t("orgAttackFactorOverTime.yAxisLabel")}
          xAxisLabel={t("orgAttackFactorOverTime.xAxisLabel")}
          xAxisColor="#2064b5"
          yAxisColor="#AEAEAE"
          behaviorName={t("orgAttackFactorOverTime.behaviourName")}
          showGradeLabel
        />
      ) : (
        <TrendingOverTimeGraphCommon
          lineData={[]}
          barData={barData}
          loader={loader}
          title={t("attackOverTimeTitle")}
          xAxisLabel={t("orgAttackFactorOverTime.yAxisLabel")}
          xAxisColor="#AEAEAE"
        />
      )}
    </ImageExportWrapper>
  )
}

export function ActionFactorTrendTable({ onOpenSideDrawer }) {
  const dispatch = useDispatch()

  useEffect(() => {
    const getBehaviorsAndScores = async () => {
      const start_date = moment(moment.now())
        .subtract(13, "months")
        .format("YYYY-MM-DD")
      const end_date = moment(moment.now()).format("YYYY-MM-DD")
      const params = {
        start_date,
        end_date,
      }

      const { data } = await getHistoricalActionScores(params)

      if (!data) return
      dispatch(setTrendOverTime(data))
    }
    getBehaviorsAndScores()
  }, [dispatch])

  return <HumanRiskTrendTable onOpenSideDrawer={onOpenSideDrawer} />
}
