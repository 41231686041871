import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { HumanRiskBehaviorsItemDetailsLeft } from "./styles"

import { useRequest } from "ahooks"

import { getActionsData } from "@src/services/apis/reputation"
import { TIME_PERIOD_MONTHS } from "../../constants"
import { isEmpty } from "@src/utils/string"
import { useSelector } from "react-redux"
import { SkeletonLoader } from "@src/utils/skeleton"
import { HumanRiskBehaviorsStatisticItem } from "./HumanRiskBehaviorsStatisticsItem"
import { formatAction } from "./formatAction"
import { useTranslation } from "react-i18next"
import { useI18nResourceBundle } from "@src/utils/useI18nResourceBundle"

export function HumanRiskBehaviorStatistics({
  passOnClick,
  id,
  person_nid,
  teaserMode = false,
}) {
  const reputations = useSelector((state) => state.get("reputationsReducer"))

  const { t } = useTranslation("dashboard")

  const mappedActionName = (
    {
      phishing: "simulated_phishing",
      "real-world-phishing": "actual_phishing",
    }[id] ?? id
  ).replaceAll("-", "_")

  const statisticStrings =
    useI18nResourceBundle("dashboard").behaviorStatistics[mappedActionName]

  const teaserStatistics = useMemo(
    () =>
      Object.keys(statisticStrings ?? {}).map((statisticKey, i) => ({
        position: -i, // Make sure we don't re-translate the title & description
        title: t(
          `behaviorStatistics.${mappedActionName}.${statisticKey}.title`,
        ),
        description: t(
          `behaviorStatistics.${mappedActionName}.${statisticKey}.description`,
          { monthCount: TIME_PERIOD_MONTHS[reputations.time_period_value] },
        ),
        value: "X".repeat(Math.floor(Math.random() * 3) + 1),
        action: mappedActionName,
      })),
    [statisticStrings, mappedActionName, t, reputations.time_period_value],
  )

  const {
    loading: insightLoader,
    data: insightConditions = teaserMode ? teaserStatistics : [],
  } = useRequest(
    () =>
      getActionsData(
        formatAction(id?.replaceAll("-", "_")),
        TIME_PERIOD_MONTHS[reputations.time_period_value],
      ),
    {
      ready: !isEmpty(id) && !teaserMode,
      refreshDeps: [id, reputations.time_period_value],
      formatResult: (res) => {
        return (
          res?.data?.details?.map((details) => ({
            ...details,
            action: res.data.action,
          })) || [
            {
              position: 0,
              title: "No details found",
              type: "percentage",
              value: "0",
              description: "No details found",
              action: id,
            },
          ]
        )
      },
    },
  )

  let filteredInsightConditions = []

  // Right now we are only loading the insights for the org, not the person, so
  // if used on IRP, we don't show the insights for now. We'll have to follow up
  // to add them for IRP.
  if (!person_nid) {
    if (id === "attack_rwp") {
      filteredInsightConditions = insightConditions.filter(
        // Position is poorly named, but it does serve as an identifier for the individual statistics
        ({ position: id }) =>
          id === 4 || // Delivered
          id === 6, // Blocked
      )
    } else {
      filteredInsightConditions = insightConditions
    }
  }

  if (!insightLoader) {
    return (
      <HumanRiskBehaviorsItemDetailsLeft>
        {filteredInsightConditions?.map((data) => {
          return (
            <HumanRiskBehaviorsStatisticItem
              passOnClick={passOnClick}
              data={data}
              key={data.position}
              monthCount={TIME_PERIOD_MONTHS[reputations.time_period_value]}
              teaserMode={teaserMode}
            />
          )
        })}
      </HumanRiskBehaviorsItemDetailsLeft>
    )
  }
  return (
    <div style={{ width: "100%" }}>
      <SkeletonLoader height={90} marginTop={10} />
    </div>
  )
}

HumanRiskBehaviorStatistics.propTypes = {
  passOnClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  teaserMode: PropTypes.bool,
}
