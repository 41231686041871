import React, { useState, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

import PageHeader from "@src/components/PageHeader"
import {
  RiskiestEntitiesSection,
  ActionFactorSection,
  AttackFactorSection,
  MostAttackedSection,
  ActionFactorSideDrawer,
} from "./components/HumanRiskData"
import HumanRisksInsightsProvider from "@src/scenes/Dashboard/components/FirstQuestion/components/HumanRisksInsights/HumanRisksInsightsProvider.js"
import ActionableInsights from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/ActionableInsights"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { HumanRiskDashboardContainer } from "./HumanRiskDashboard.styles"
import { DashboardCard } from "@src/components/DashboardCard"
import AccessAndAttackFactorGraph from "@src/scenes/HumanRiskDashboard/components/AccessAndAttackFactorGraph/AccessAndAttackFactorGraph"

import { getLastUpdateTimeLabel } from "./utils"

import { getOfferingPlan } from "@src/services/redux/company/selectors"
import {
  TimePeriodSelect,
  ActionFactorTrendTable,
  HumanRiskScoreTrendGraph,
} from "@src/scenes/HumanRiskDashboard/components/TrendingOverTime/TrendingOverTime"
import { OFFERING_PLANS } from "@src/constants"
import ExportImage from "@src/components/ExportImage"
import { SpacingY } from "@src/components/SpacingY"
import { SpacingX } from "@src/components/SpacingX"
import HumanRiskScoreGauge from "../../components/HumanRiskScoreGauge/HumanRiskScoreGauge"
import { SummaryStatisticsSection } from "./components/SummaryStatistics"
import { getGlobalConfig } from "@src/globalConfig"
import {
  showSideDrawer,
  setActions,
} from "@src/services/redux/analysisIndividuals/actions"
import { selectLatestScoringDatetime } from "@src/services/redux/reputations/selectors"
import { HumanRiskDashboardAttackFactorInfoTooltip } from "@src/components/DashboardInfoTooltips"
import { useTranslation } from "react-i18next"
import { HumanRiskBehaviorsTeaser } from "./components/HumanRiskBehaviors/HumanRiskBehaviorsTeaser"
import { useIsBasicMimecastCustomer } from "@src/utils/useIsBasicMimecastCustomer"
import { EducationCard } from "./components/EducationCard"
import { EducationModal } from "./components/EducationModal"

function HumanRiskDashboard() {
  const { t } = useTranslation("dashboard")
  const dispatch = useDispatch()
  const [keyFactor, setKeyFactor] = useState("")
  const [currentFactorScore, setCurrentFactorScore] = useState(false)
  const [trendValue, setTrendValue] = useState(false)
  const lastScoreUpdate = useSelector(selectLatestScoringDatetime)

  const reputations = useSelector((state) => state.get("reputationsReducer"))

  const loader = reputations.apiLoader

  const isAssessPlan = getOfferingPlan() === OFFERING_PLANS.ASSESS

  const humanRiskScore = reputations.risk_scores?.overall || 0

  const gaugeFooterText = t("humanRiskScoreCard.footerText")

  const [showEducationModal, setShowEducationModal] = useState(false)

  const reviewOnClick = useCallback(
    ({ keyFactor, currentFactorScore, trendValue }) => {
      dispatch(showSideDrawer())
      dispatch(setActions({ name: "", trending: 0, value: 0 }))
      setKeyFactor(keyFactor)
      setCurrentFactorScore(currentFactorScore)
      setTrendValue(trendValue)
    },
    [dispatch],
  )

  const isBasicMimecastCustomer = useIsBasicMimecastCustomer()

  return (
    <>
      <ActionFactorSideDrawer
        keyFactor={keyFactor}
        currentFactorScore={currentFactorScore}
        sideDrawerTrendValue={trendValue}
      />
      {loader && <ActivityIndicator active />}
      <HumanRiskDashboardContainer>
        <PageHeader
          title={t(getGlobalConfig("DASHBOARD_TITLE"))}
          lastUpdatedTimeLabel={
            getGlobalConfig("SHOW_LAST_SCORING_DATETIME")
              ? getLastUpdateTimeLabel(lastScoreUpdate)
              : ""
          }
          contentOnTheRightOfTitle={
            <SpacingX size="lg">
              <TimePeriodSelect />
              <ExportImage />
            </SpacingX>
          }
        />
        <SpacingY size="md">
          <SpacingX size="md">
            <HumanRiskScoreGauge
              score={humanRiskScore}
              footerText={gaugeFooterText}
              containerWidth={350}
            />
            <SpacingY size="md" style={{ flex: 1 }}>
              <DashboardCard
                flex="1"
                title={t("scoreTrendsOverTimeTitle")}
                variant="transparent"
                imageExportId="HumanRiskScoreOverTime"
              >
                <HumanRiskScoreTrendGraph />
              </DashboardCard>
              {!isBasicMimecastCustomer && (
                <DashboardCard>
                  <SummaryStatisticsSection onOpenSideDrawer={reviewOnClick} />
                </DashboardCard>
              )}
            </SpacingY>
          </SpacingX>
          {isBasicMimecastCustomer && (
            <>
              <EducationCard
                onLearnMoreClick={() => setShowEducationModal(true)}
              />
              <EducationModal
                showModal={showEducationModal}
                onClose={() => setShowEducationModal(false)}
              />
            </>
          )}
          <SpacingX size="md" wrap={false}>
            <DashboardCard
              flex="3"
              title={t(getGlobalConfig("BEHAVIOR_BREAKDOWN_TITLE"))}
              subtitle={t("humanRiskBehaviourBreakdown.subtitle")}
              imageExportId="ActionFactor"
            >
              <ActionFactorSection onOpenSideDrawer={reviewOnClick} />
              {isBasicMimecastCustomer && (
                <>
                  <hr />
                  <HumanRiskBehaviorsTeaser
                    onLearnMoreClick={() => setShowEducationModal(true)}
                  />
                </>
              )}
            </DashboardCard>
            <DashboardCard flex="2" title={t("riskiestEntitiesSectionTitle")}>
              <RiskiestEntitiesSection />
            </DashboardCard>
          </SpacingX>
          {getGlobalConfig("ENABLE_BEHAVIOR_TRENDS_OVER_TIME") && (
            <DashboardCard
              title={t("actionFactorTrendTableTitle", {
                descriptor: t(getGlobalConfig("BEHAVIOR_DESCRIPTOR")),
              })}
            >
              <ActionFactorTrendTable onOpenSideDrawer={reviewOnClick} />
            </DashboardCard>
          )}
          <SpacingX size="md" wrap={false}>
            <DashboardCard
              flex="3"
              title={t("attackFactors.title")}
              subtitle={t("attackFactors.subtitle")}
              info={<HumanRiskDashboardAttackFactorInfoTooltip />}
            >
              <AttackFactorSection onOpenSideDrawer={reviewOnClick} />
            </DashboardCard>
            <DashboardCard flex="2" title={t("mostAttackedSectionTitle")}>
              <MostAttackedSection />
            </DashboardCard>
          </SpacingX>
          {getGlobalConfig("ENABLE_ATTACK_FACTOR_DISTRIBUTION") && (
            <DashboardCard
              title={t("attackFactors.distributionTitle")}
              imageExportId="AccessAndAttackFactor"
            >
              <AccessAndAttackFactorGraph
                keyFactor=""
                graphType="attack_factor"
                showHeader={false}
              />
            </DashboardCard>
          )}
          {getGlobalConfig("ENABLE_RECOMMENDATIONS_TO_REDUCE_RISK") &&
            !isAssessPlan && (
              <HumanRisksInsightsProvider actionableDetailsFlag={false} />
            )}
          {getGlobalConfig("ENABLE_DASHBOARD_INSIGHTS") && !isAssessPlan && (
            <ActionableInsights />
          )}
        </SpacingY>
      </HumanRiskDashboardContainer>
    </>
  )
}

export default HumanRiskDashboard
