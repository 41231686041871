import React, { useState } from "react"
import IndividualProfileContextProvider, {
  useIndividualProfileState,
} from "./IndividualProfileContextProvider"
import { Tabs, Tab } from "@src/components/MimecastReskin"
import { ProfileInfo } from "./ProfileInfo"
import { PolicyActionLog } from "./PolicyActionLog"
import { EventInfo } from "./EventInfo"
import { getOfferingPlan } from "@src/services/redux/company/selectors"
import { OFFERING_PLANS } from "@src/constants"
import { useSelector } from "react-redux"
import { getWhoAMI } from "@src/services/selectors/visionSelectors"
import { darklyGetFlag } from "@src/utils/darkly"
import { viewType } from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/utils"
import { ApplicationsScreen } from "../ApplicationsScreen"
import { useParams } from "react-router-dom"
import { TabsWrapper } from "./ProfileInfo/components/IndividualHumanResourceInformation/style"
import { IndividualHumanResourceInformation } from "./ProfileInfo/components"
import { MimecastCgBreadcrumbs } from "@src/MimecastCgBreadcrumbs"
import { SpacingX } from "@src/components/SpacingX"
import { TimePeriodSelect } from "./ProfileInfo/TimePeriodSelect"
import { getGlobalConfig } from "@src/globalConfig"
import { ActionLogsScreen } from "../ActionLogsScreen"
import { useTranslation } from "react-i18next"

function IndividualProfilePage() {
  const { t } = useTranslation("individualRiskProfile")
  const [selectedTab, _setSelectedTab] = useState(0)
  const hideActionLog = getOfferingPlan() === OFFERING_PLANS.ASSESS
  const userData = useSelector(getWhoAMI)
  const showMDV2 = darklyGetFlag("manager-dashboard-v2")
  const isManagerView = (showMDV2 ? viewType(userData) : "") === "manager"
  const isItdrEnabled = darklyGetFlag("itdr-behaviors")
  const { profileId } = useParams()
  const [eventType, setEventType] = useState("")

  const setSelectedTab = (tabIndex) => {
    _setSelectedTab(tabIndex)
    setTabsToRender((prev) => new Set(prev).add(tabIndex))
  }

  const setSelectedTabByTitle = (title) => {
    setSelectedTab(tabs.findIndex((tab) => tab.title === title))
  }

  // Keep track of rendered tabs. Don't render the tab until it is first
  // clicked, but after first render don't unmount it to ensure that we don't
  // reload the data when switching between tabs, since it is common to flip
  // between Profile Info and Events.
  const [tabsToRender, setTabsToRender] = useState(new Set([0]))

  const tabs = [
    {
      show: true,
      title: t("profileInfoTabs.profileInfoLabel"),
      render: () => (
        <ProfileInfo
          isManagerView={isManagerView}
          onSwitchToActionlogsTab={() => {
            setSelectedTabByTitle("Action Log")
          }}
          onSwitchToEventsTab={(eventType) => {
            setSelectedTabByTitle("Events")
            setEventType(
              {
                attack_rwp: "actual_phishing",
                attack_malware: "malware",
                phishing: "simulated_phishing",
                real_world_phishing: "actual_phishing",
              }[eventType] || eventType,
            )
          }}
        />
      ),
    },
    {
      show: !hideActionLog && !isManagerView,
      title: t("profileInfoTabs.actionLog"),
      render: () =>
        getGlobalConfig("ENABLE_IRP_ACTION_LOG_GROUPING") ? (
          <PolicyActionLog />
        ) : (
          <ActionLogsScreen personId={profileId} showTitle={false} />
        ),
    },
    {
      show: isItdrEnabled && !isManagerView,
      title: t("profileInfoTabs.applications"),
      render: () => (
        <div style={{ marginTop: "24px" }}>
          <ApplicationsScreen hidePageTitle={true} es_person_id={profileId} />
        </div>
      ),
    },
    {
      show: !isManagerView,
      title: t("profileInfoTabs.events"),
      render: () => <EventInfo key={selectedTab} eventTypeFilter={eventType} />,
    },
  ].filter((tab) => tab.show)

  return (
    <IndividualProfileContextProvider>
      <Breadcrumbs profileId={profileId} />
      <SpacingX justify="space-between">
        <div style={{ flex: "1" }}>
          <IndividualHumanResourceInformation />
        </div>
        {selectedTab === 0 && <TimePeriodSelect />}
      </SpacingX>
      <TabsWrapper>
        <Tabs
          onChange={(i) => {
            setSelectedTab(i)
          }}
          currentTabIndex={selectedTab}
        >
          {tabs.map((tab, i) => (
            <Tab key={tab.title} title={tab.title}>
              {tabsToRender.has(i) ? (
                <div data-analytics={tab.title}>{tab.render()}</div>
              ) : (
                <></>
              )}
            </Tab>
          ))}
        </Tabs>
      </TabsWrapper>
    </IndividualProfileContextProvider>
  )
}

function Breadcrumbs({ profileId }) {
  const { individualData } = useIndividualProfileState()
  const fullName = `${individualData?.first_name || ""} ${individualData?.last_name || ""}`

  return (
    <MimecastCgBreadcrumbs
      breadcrumbs={[
        {
          label: fullName,
          url: `/admin/engagement/vision2/${profileId}/profile`,
        },
      ]}
    />
  )
}

export default IndividualProfilePage
