import React from "react"
import styled from "styled-components"
import { Badge } from "@elevate_security/elevate-component-library"

import { VERY_LOW, LOW, MEDIUM, HIGH, VERY_HIGH } from "@src/theme"
import { useTranslation } from "react-i18next"

function mapScoreToIndex(score: number, buckets: number = 5): number {
  const MAX_SCORE = 10
  return Math.min(Math.floor(score / (MAX_SCORE / buckets)), buckets - 1)
}

type Props = {
  score: number
  shape: "smallboldround" | "largeboldround"
  text: string
}

export function WordScoreBadge(props: Omit<Props, "text">) {
  const { t } = useTranslation("dashboard")

  const namedScores = [
    t("humanRiskScoreCard.scoreGauge.veryLow"),
    t("humanRiskScoreCard.scoreGauge.low"),
    t("humanRiskScoreCard.scoreGauge.medium"),
    t("humanRiskScoreCard.scoreGauge.high"),
    t("humanRiskScoreCard.scoreGauge.veryHigh"),
  ]

  return (
    <ScoreBadge {...props} text={namedScores[mapScoreToIndex(props.score)]} />
  )
}

export function ScoreBadge({ score, shape = "smallboldround", text }: Props) {
  const themes = [VERY_LOW, LOW, MEDIUM, HIGH, VERY_HIGH]

  return (
    <Badge text={text} shape={shape} theme={themes[mapScoreToIndex(score)]} />
  )
}

const ExtraPaddingBadge = styled(Badge)`
  padding-right: 16px;
  padding-left: 16px;
`

export function TeaserScoreBadge() {
  return (
    <ExtraPaddingBadge text="?" shape="smallboldround" theme="eventsgray" />
  )
}
