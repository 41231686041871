import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { EducationCardWrapper } from "./EducationCard.styles"
import { Button } from "@src/components/MimecastReskin"
import { SpacingY } from "@src/components/SpacingY"
import { SpacingX } from "@src/components/SpacingX"
import VideoPreviewImage from "@src/assets/human-risk-video-preview.png"
import { TransparentOverlay } from "@src/components/TransparentOverlay"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/pro-regular-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { LearnMoreButton } from "../HumanRiskBehaviors/LearnMoreButton"

const LOCALSTORAGE_KEY = "humanRisk_educationCardDismissed"

export function EducationCard({ onLearnMoreClick = () => {} }) {
  const { t } = useTranslation("dashboard")

  const [showCard, setShowCard] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem(LOCALSTORAGE_KEY)) {
      setShowCard(true)
    }
  }, [])

  if (!showCard) {
    return null
  }

  return (
    <>
      <EducationCardWrapper>
        <SpacingX wrap={false}>
          <SpacingY style={{ flex: "1" }} justify="space-between">
            <div style={{ fontSize: "1.75em", fontWeight: "bold" }}>
              {t("educationCard.didYouKnow")}
            </div>
            <div style={{ fontSize: "1.25em" }}>
              {t("educationCard.description")}
            </div>
            <SpacingX>
              <LearnMoreButton onClick={onLearnMoreClick} />
              <Button
                theme="ghost"
                onClick={() => {
                  setShowCard(false)
                  localStorage.setItem(LOCALSTORAGE_KEY, "yes")
                }}
              >
                {t("educationCard.dontShowAgain")}
              </Button>
            </SpacingX>
          </SpacingY>
          <div>
            <TransparentOverlay
              onClick={onLearnMoreClick}
              when={true}
              cursor="pointer"
              activationElement={<FontAwesomeIcon icon={faPlay as IconProp} />}
              overlayOpacity="1"
              childOpacity="1"
              overlayStyles={{
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "36px",
              }}
            >
              <img src={VideoPreviewImage} style={{ height: "170px" }} />
            </TransparentOverlay>
          </div>
        </SpacingX>
      </EducationCardWrapper>
    </>
  )
}
