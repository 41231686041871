import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { SpacingX } from "@src/components/SpacingX"
import { Button } from "@src/components/MimecastReskin"

const Wrapper = styled.div`
  button {
    border: 1px solid rgb(133, 166, 224);
    background-color: white;
    &:hover {
      background-color: white;
    }
  }
`

export function LearnMoreButton({
  to,
  onClick = () => {},
}: {
  to?: string
  onClick?: () => void
}) {
  const { t } = useTranslation("dashboard")

  return (
    <Wrapper>
      <Button to={to} onClick={onClick} theme="ghost" newTab={true}>
        <SpacingX alignItems="center">
          <div>{t("teaserText.learnMoreText")}</div>
          <FontAwesomeIcon icon={faArrowRight} size="lg" />
        </SpacingX>
      </Button>
    </Wrapper>
  )
}
