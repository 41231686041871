import {
  Button as _Button,
  ButtonLink as _ButtonLink,
  CustomSelect as _CustomSelect,
  ComboBox as _ComboBox,
  Tabs as _Tabs,
  Tab as _Tab,
  Table as _Table,
  Input as _Input,
  EventsLog as _EventsLog,
  HRSGraph as _HRSGraph,
  Toggle as _Toggle,
  SearchInput as _SearchInput,
  Checkbox as _Checkbox,
} from "@elevate_security/elevate-component-library"

import { withMimecastTheme } from "./withMimecastTheme"

// For components without type definitions, specify any as the type param if you
// need to get things to type check in TS code using these components (e.g. SearchInput)
export const Button = withMimecastTheme<any>(_Button)
export const ButtonLink = withMimecastTheme(_ButtonLink)
export const CustomSelect = withMimecastTheme(_CustomSelect)
export const ComboBox = withMimecastTheme(_ComboBox)
export const Tabs = withMimecastTheme(_Tabs)
export const Tab = withMimecastTheme(_Tab)
export const Table = withMimecastTheme(_Table)
export const Input = withMimecastTheme(_Input)
export const EventsLog = withMimecastTheme(_EventsLog)
export const HRSGraph = withMimecastTheme(_HRSGraph)
export const Toggle = withMimecastTheme(_Toggle)
export const SearchInput = withMimecastTheme<any>(_SearchInput)
export const Checkbox = withMimecastTheme<any>(_Checkbox)
