import { getGlobalConfigKey } from "@src/globalConfig"
import { selectActiveActions } from "@src/services/redux/reputations/selectors"
import { useSelector } from "react-redux"

/**
 * A "basic" customer is one that has just been given "Human Risk For All" and
 * doesn't have Engage, or any integrations hooked up. We assume they only have
 * Actual Phishing data since they are almost definitely an email gateway
 * customer if they are a Mimecast customer.
 */
export function useIsBasicMimecastCustomer(): boolean {
  const activeActions = (useSelector(selectActiveActions) ?? []).filter(
    (action: string) => action !== "overall",
  )

  if (getGlobalConfigKey() === "elevate") {
    return false
  }

  return (
    activeActions.length === 1 && activeActions[0] === "real-world-phishing"
  )
}
