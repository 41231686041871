import {
  configureAuthInterceptor,
  configureErrorResponseInterceptor,
} from "@src/services/fetcher"
import { getSubdomain } from "@src/services/fetcher/utils"

const UNAUTHORIZED = "global:unauthorized"

export function getTokenFromLocalStorage() {
  return localStorage.getItem("JWT") || localStorage.getItem("managerToken")
}

export function configureElevateAuth() {
  configureAuthInterceptor((config) => {
    if (!config.headers.Authorization) {
      const token = getTokenFromLocalStorage()
      if (token) {
        // Manager token might be prefixed with Token, so we need to replace it
        // with Bearer in MOST cases -- some endpoints will set the
        // Authorization header manually in the case where Token is actually
        // expected instead of Bearer.
        const bearerToken = token.replace(/Token/g, "Bearer")
        config.headers.Authorization = bearerToken
      }
    }
    config.headers["X-Tenant"] = getSubdomain()
    return config
  })

  configureErrorResponseInterceptor((error) => {
    const { response } = error

    // if 401 is returned, dispatch event to initiate authorization flow
    if (response && response.status === 401) {
      window.dispatchEvent(
        new Event(UNAUTHORIZED, { bubbles: true, cancelable: false }),
      )
    }
    return Promise.reject(error)
  })
}
