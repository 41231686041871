import React from "react"
import styled from "styled-components"
import { SpacingX } from "@src/components/SpacingX"
import { Button } from "@src/components/MimecastReskin"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons"

// Button with `to` prop has some weird behaviour, should likely switch for
// something else
const ButtonLinkAnchorColorFix = styled.div`
  a {
    color: unset !important;
  }
`

export function IntegrationsHubButtonLink() {
  const { t } = useTranslation("dashboard")

  return (
    <ButtonLinkAnchorColorFix>
      <Button
        // We should be opening in same tab ideally, but right now there's no way
        // to get shell to detect the route change.
        to={`${window.location.origin}/admin/integrations/hub`}
        theme="default"
        newTab={true}
      >
        <SpacingX alignItems="center">
          <div>{t("teaserText.integrationsHubText")}</div>
          <FontAwesomeIcon icon={faArrowRightArrowLeft} size="lg" />
        </SpacingX>
      </Button>
    </ButtonLinkAnchorColorFix>
  )
}
