import HelpTooltip from "@src/components/HelpTooltip"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useIndividualProfileState } from "../../../IndividualProfileContextProvider"
import { AttackInfoContainer } from "./styles"
import { TOOLTIP_DESCRIPTION } from "./utils"
import { useSelector } from "react-redux"
import HumanRiskScoreGauge from "@src/components/HumanRiskScoreGauge/HumanRiskScoreGauge"
import { HumanRiskBehaviorsSection } from "@src/scenes/HumanRiskDashboard/components/HumanRiskBehaviors/HumanRiskBehaviors"
import { DashboardCard } from "@src/components/DashboardCard"
import { SpacingY } from "@src/components/SpacingY"
import { SpacingX } from "@src/components/SpacingX"
import { IRPSummaryStatisticsSection } from "@src/scenes/IndividualProfilePage/IRPSummaryStatistics"
import { HumanRiskScoreTrendGraphIRP } from "../TrendAttackFactor/TrendingOverTimeIRP"
import TrendAttackGraph from "../TrendAttackFactor/TrendAttackGraph"
import { selectActiveActions } from "@src/services/redux/reputations/selectors"
import { ComboBoxWithOverlap } from "@src/components/ComboBoxWithOverlap"
import { getGlobalConfig } from "@src/globalConfig"
import { useTranslation } from "react-i18next"
import { useIsBasicMimecastCustomer } from "@src/utils/useIsBasicMimecastCustomer"
import { HumanRiskBehaviorsTeaser } from "@src/scenes/HumanRiskDashboard/components/HumanRiskBehaviors/HumanRiskBehaviorsTeaser"
import { EducationModal } from "@src/scenes/HumanRiskDashboard/components/EducationModal"

export const AttackInfo = ({ value, description }) => (
  <AttackInfoContainer>
    <h1>{value}</h1>
    <span>
      {description.toUpperCase()}
      <HelpTooltip body={TOOLTIP_DESCRIPTION[description]} placement="bottom" />
    </span>
  </AttackInfoContainer>
)

AttackInfo.propTypes = {
  value: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

function IRPGraphSelect({ onChange, value }) {
  const { t } = useTranslation("dashboard")
  const graphOptions = [
    {
      label: t(
        "humanRiskIndividual.irpGraph.graphOptions.showhumanRiskScoreOnly",
      ),
      value: "hrs_only",
    },
    {
      label: t("humanRiskIndividual.irpGraph.graphOptions.showAllBehaviours"),
      value: "all_behaviors",
    },
  ]
  const chosenOption = graphOptions.find((option) => option.value === value)
  return (
    <ComboBoxWithOverlap
      value={chosenOption}
      data={graphOptions}
      onChange={(selected) => {
        onChange(selected.value)
      }}
      defaultValue={chosenOption}
    />
  )
}

export default function IndividualHumanRiskInfoSection({
  actionData,
  onSwitchToEventsTab,
  onSwitchToActionlogsTab,
  isManagerView,
}) {
  const { t } = useTranslation("dashboard")
  const { individualData } = useIndividualProfileState()
  const person_nid = individualData.es_person_id
  const timePeriodValue =
    useSelector((state) => state.get("reputationsReducer"))
      ?.time_period_value || ""
  const activeActions = useSelector(selectActiveActions)

  const [chosenGraph, setChosenGraph] = useState("hrs_only")

  const [showEducationModal, setShowEducationModal] = useState(false)

  const isBasicMimecastCustomer = useIsBasicMimecastCustomer()

  if (!individualData) return null

  const score = isManagerView
    ? individualData?.actions?.overall
    : individualData?.risks?.overall

  const gaugeFooterText = t("humanRiskIndividual.riskRatingFooterText")

  return (
    <SpacingY size="md">
      <SpacingX size="md">
        <HumanRiskScoreGauge
          score={score}
          footerText={gaugeFooterText}
          isFooterInline={true}
          containerWidth={300}
        />
        <DashboardCard
          flex="1"
          title={t("humanRiskIndividual.scoreTrendsOverTimeTitle")}
          variant="transparent"
          renderControls={() => {
            return getGlobalConfig("ENABLE_IRP_ALL_TRENDS_GRAPH") ? (
              <div style={{ width: "270px" }}>
                <IRPGraphSelect
                  onChange={(value) => {
                    setChosenGraph(value)
                  }}
                  value={chosenGraph}
                />
              </div>
            ) : null
          }}
        >
          {chosenGraph === "hrs_only" && (
            <HumanRiskScoreTrendGraphIRP isManagerView={isManagerView} />
          )}
          {chosenGraph === "all_behaviors" && (
            <TrendAttackGraph
              hideItem={isManagerView}
              timePeriodValue={timePeriodValue}
              activeActions={activeActions}
              showDropDown={false}
            />
          )}
        </DashboardCard>
      </SpacingX>
      <SpacingX wrap={false} size="md">
        <DashboardCard
          flex="3"
          title={t(getGlobalConfig("BEHAVIOR_BREAKDOWN_TITLE"))}
          subtitle={t("humanRiskIndividual.subtitle")}
        >
          <HumanRiskBehaviorsSection
            actionFactorScoreData={actionData}
            person_nid={person_nid}
            timePeriodValue={timePeriodValue}
            onClickOverride={isManagerView ? null : onSwitchToEventsTab}
            showEventCounts={!isManagerView}
          />
          {isBasicMimecastCustomer && (
            <>
              <hr />
              <HumanRiskBehaviorsTeaser
                expandSections={false}
                onLearnMoreClick={() => setShowEducationModal(true)}
              />
              <EducationModal
                onClose={() => setShowEducationModal(false)}
                showModal={showEducationModal}
              />
            </>
          )}
        </DashboardCard>
        {!isManagerView && !isBasicMimecastCustomer && (
          <DashboardCard
            flex="2"
            title={t("humanRiskIndividual.riskResponses")}
          >
            <IRPSummaryStatisticsSection
              onSwitchToActionlogsTab={onSwitchToActionlogsTab}
              person_nid={person_nid}
              timePeriodValue={timePeriodValue}
            />
          </DashboardCard>
        )}
      </SpacingX>
    </SpacingY>
  )
}
