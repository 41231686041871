import React from "react"
import PropTypes from "prop-types"
import { HumanRiskBehaviorsBox } from "./styles"

import { filterActionFactorData } from "@src/utils/dataFilter"
import { useSelector } from "react-redux"

import { HumanRiskBehaviorsItem } from "./HumanRiskBehaviorsItem"
import { selectActiveActions } from "@src/services/redux/reputations/selectors"
import { SkeletonLoader } from "@src/utils/skeleton"

export const HumanRiskBehaviorsSection = ({
  actionFactorScoreData,
  onOpenSideDrawer = () => {},
  person_nid,
  scoreType = "number",
  isAttackFactor = false,
  onClickOverride,
  eventTotalsByBehavior = {},
  activeActionsOverride,
  teaserMode = false,
  expandSections = false,
  loading = false,
  showEventCounts = true,
}) => {
  const activeActions = useSelector(selectActiveActions)

  const actionsToDisplay = activeActionsOverride ?? activeActions

  const actionFactorData = filterActionFactorData(
    actionsToDisplay,
    actionFactorScoreData,
  )
    .slice()
    .sort((a, b) => b.current - a.current)

  const attackFactorData = filterActionFactorData(
    actionsToDisplay ?? [],
    actionFactorScoreData,
    {
      predicate: (dashedKey, activeActions) => {
        const normalizedKey = dashedKey.replaceAll("attack-", "")
        return (
          dashedKey.startsWith("attack-") &&
          activeActions.includes(
            { rwp: "real-world-phishing" }[normalizedKey] || normalizedKey,
          )
        )
      },
    },
  )
    .slice()
    .sort((a, b) => b.current - a.current)

  const tileData = isAttackFactor ? attackFactorData : actionFactorData

  return loading ? (
    <SkeletonLoader height={250} />
  ) : (
    <HumanRiskBehaviorsBox>
      {tileData.map(({ key, current, prev }) => {
        return (
          <HumanRiskBehaviorsItem
            key={key}
            id={key}
            current={current}
            prev={prev}
            onOpenSideDrawer={onOpenSideDrawer}
            person_nid={person_nid}
            scoreType={scoreType}
            onClickOverride={onClickOverride}
            eventCountOverride={
              eventTotalsByBehavior[
                { attack_rwp: "actual_phishing", attack_malware: "malware" }[
                  key
                ] || key
              ]
            }
            teaserMode={teaserMode}
            expand={expandSections}
            showEventCount={showEventCounts}
          />
        )
      })}
    </HumanRiskBehaviorsBox>
  )
}

HumanRiskBehaviorsSection.propTypes = {
  actionFactorScoreData: PropTypes.array.isRequired,
  person_nid: PropTypes.string,
  scoreType: PropTypes.string,
  isAttackFactor: PropTypes.bool,
  onClickOverride: PropTypes.func,
  activeActionsOverride: PropTypes.array,
  teaserMode: PropTypes.bool,
}
