import React, { useCallback } from "react"

import { Table } from "@src/components/MimecastReskin"
import { useTranslation } from "react-i18next"

export function LocalizedTable(props: any) {
  const { t } = useTranslation("riskAnalysis")

  const showPageItemList = useCallback(
    (value) => {
      return t(
        "columnChooserTexts.columnLabelOption.tableLables.pageSizeLabel",
        {
          defaultValue: `Show ${value} items`,
          value,
        },
      )
    },
    [t],
  )

  const showPaginationTooltip = useCallback(
    (value) => {
      return t(
        "columnChooserTexts.columnLabelOption.tableLables.paginationTooltip",
        {
          defaultValue: `Navigation to page ${value}`,
          value,
        },
      )
    },
    [t],
  )

  return (
    <Table
      {...props}
      showPageItemList={showPageItemList}
      showPaginationTooltip={showPaginationTooltip}
    />
  )
}
