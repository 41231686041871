import React, { useState, useCallback } from "react"
import { Modal } from "@src/components/Modal"
import { Button, Checkbox } from "@src/components/MimecastReskin"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { SpacingX } from "../SpacingX"
import { SpacingY } from "../SpacingY"
import styled from "styled-components"
import { useRequest } from "ahooks"
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { navigateWithRefresh } from "@src/utils/navigateWithRefresh"
import { useTranslation } from "react-i18next"
import { ScrollableDiv } from "./ScrollableDiv"

// Exported for testing
export const FixedContentHeightModal = styled(Modal)`
  [class^="Modalstyles__ModalContent"] {
    height: 80vh;
  }
`

export function TermsAndConditionsModal({
  onSuccess = () => {},
}: {
  onSuccess?: () => void
}) {
  const [isAgreeChecked, setIsAgreeChecked] = useState(false)
  const [showModal, setShowModal] = useState(true)
  const [error, setError] = useState(false)
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false)
  const { t } = useTranslation("dashboard")

  const { run: handleAccept, loading: isAccepting } = useRequest(
    () => {
      setError(false)
      // TODO: Make real request here
      return new Promise((resolve) => setTimeout(resolve, 2000))
        .then(() => {
          setShowModal(false)
          onSuccess()
        })
        .catch(() => {
          setError(true)
        })
    },
    { manual: true },
  )

  const handleCancel = useCallback(() => {
    if (!isAccepting) {
      // Not using react-router here in order to force the shell app to detect the
      // route change. This results in a full reload but that's fine for now.
      navigateWithRefresh(`${window.location.origin}/admin`)
    }
  }, [isAccepting])

  const handleScrollBottom = useCallback(() => {
    setHasScrolledToBottom(true)
  }, [])

  return (
    <FixedContentHeightModal
      isOpen={showModal}
      onClose={handleCancel}
      header="Human Risk Services (Engage, Aware, Incydr) Terms and Conditions"
      rightButtons={[
        <Button theme="ghost" onClick={handleCancel} key="cancel" autoFocus>
          {t("termsAndConditionsModal.cancelButton")}
        </Button>,
        <Button
          disabled={!isAgreeChecked || isAccepting}
          onClick={handleAccept}
          key="agree"
        >
          <SpacingX alignItems="center">
            <div>{t("termsAndConditionsModal.agreeButton")}</div>
            <FontAwesomeIcon icon={faArrowRight} size="lg" />
          </SpacingX>
        </Button>,
      ]}
    >
      <SpacingY style={{ height: "100%" }} size="lg">
        <ScrollableDiv
          style={{ flex: "1", overflowY: "scroll" }}
          onScrollBottom={handleScrollBottom}
        >
          <TermsAndConditionContent />
        </ScrollableDiv>
        <SpacingX justify="space-between">
          <div>
            <Checkbox
              label={t("termsAndConditionsModal.agreeCheckbox")}
              disabled={!hasScrolledToBottom}
              checked={isAgreeChecked}
              onChange={(checked: boolean) => {
                setIsAgreeChecked(checked)
              }}
            />
          </div>
          {error && (
            <div style={{ fontSize: "14px", color: "red" }}>
              <SpacingX alignItems="center">
                <FontAwesomeIcon
                  icon={faCircleExclamation as IconProp}
                  size="lg"
                />
                <div>{t("termsAndConditionsModal.errorMessage")}</div>
              </SpacingX>
            </div>
          )}
        </SpacingX>
      </SpacingY>
    </FixedContentHeightModal>
  )
}

const TermsAndConditionsWrapper = styled.div`
  a {
    color: blue;
    text-decoration: underline;
  }
`

// Eventually we may need translated T&Cs, but they should be provided by legal,
// not sent to Smartling like the other UI strings.
/* eslint-disable react/no-unescaped-entities, i18next/no-literal-string */
function TermsAndConditionContent() {
  return (
    <TermsAndConditionsWrapper>
      <p>
        These terms and conditions ("Human Risk Terms") govern Customer's use of
        the Mimecast Engage, Aware, and Incydr services (collectively "Human
        Risk Services") and are an addendum to and form part of the services
        agreement which is in place between the parties, or which will be
        enacted concurrently with these Human Risk Terms (the "Agreement"). Any
        capitalized terms not otherwise defined herein have the same meanings as
        those noted in the Agreement. If there is any conflict between these
        Human Risk Terms and the Agreement (and, if applicable, any separate
        data processing agreement between the parties), then these Human Risk
        Terms shall take precedence.
      </p>
      <p>
        <strong>
          BY CLICKING 'I ACCEPT' YOU (i) AGREE TO THE TERMS AND CONDITIONS OF
          THESE HUMAN RISK TERMS WHICH WILL FORM A BINDING CONTRACT BETWEEN
          MIMECAST AND THE CORPORATION, BUSINESS OR ENTITY YOU REPRESENT (THE
          "CUSTOMER"); (ii) AGREE THAT HUMAN RISK SERVICES ARE SUBJECT TO BOTH
          THESE HUMAN RISK TERMS AND THE AGREEMENT; AND (iii) REPRESENT AND
          WARRANT THAT YOU HAVE THE POWER AND AUTHORITY TO BIND THE CUSTOMER TO
          THESE HUMAN RISK TERMS. IF YOU DO NOT AGREE TO THE TERMS AND
          CONDITIONS OF THESE HUMAN RISK TERMS, OR YOU DO NOT HAVE THE POWER AND
          AUTHORITY TO ACT ON BEHALF OF AND BIND THE CUSTOMER, DO NOT PROCEED TO
          ACCEPT THESE HUMAN RISK TERMS OR CONTINUE WITH USE OF THE HUMAN RISK
          SERVICES.
        </strong>
      </p>
      <p>
        <strong>1. Human Risk Services.</strong>
        <br />
        1.1 Mimecast provides certain services which are intended to provide
        Human Risk Awareness and Training. Customer will be provided with these
        Human Risk Terms when purchasing such Services. Customer can enable
        certain application program interfaces ("
        <strong>APIs</strong>") to enable the Human Risk Services to work in
        conjunction with certain third-party services, systems, and/or
        applications. The process to gain access to the APIs will be made
        available to Customer upon request. Customer is responsible for ensuring
        that the information provided to Mimecast to enable any API is and
        remains accurate and up to date.
        <br />
        1.1.1 Where Mimecast supplies Customer with a Mimecast API, all access
        keys, authentication procedures, and data to which Customer gains access
        or which is provided to Customer in connection with Customer's use of
        the Mimecast API, excluding Customer Data, is the Confidential
        Information of Mimecast. Mimecast may revoke access to any API at any
        time without notice to Customer if Mimecast, in its reasonable
        discretion, believes necessary or appropriate.
        <br />
        1.1.2 Customer acknowledges and agrees that Mimecast is not responsible
        for any third-party APIs and no representations, warranties or
        commitments are made regarding the same. It is Customer's sole
        responsibility to ensure it has valid licences for, and access to, all
        applicable any third-party APIs necessary to access and derive benefit
        from the Human Risk Services. Customer's access and use of such any
        third-party APIs shall be governed solely by the terms and conditions of
        such any third-party APIs. If a third-party modifies its APIs so that
        they no longer interoperate with the Human Risk Services or imposes
        requirements on interoperability that are unreasonable for Mimecast,
        Mimecast may cease or suspend its provision of interoperability between
        the Human Risk Services and the affected third-party API, without
        liability.
        <br />
        1.1.3 Mimecast is not responsible for the security of the Customer Data
        until such time that the Customer Data enters the Mimecast environment,
        and all such transfers are conducted on Customer's own volition and
        risk. To the extent Customer Data is retrieved from or provided by
        third-party APIs, Mimecast will not be liable for the condition of such
        Customer Data, including, but not limited to any Customer Data that is
        incorrect, incomplete, corrupt, or missing. 1.2 The Human Risk Services
        may include Material. Mimecast and its third-party licensors will retain
        all interest in and ownership of the Material. Mimecast grants to the
        Customer a non-exclusive licence to use the Material for the duration of
        Customer's purchase of the Human Risk Services. "
        <strong>Material</strong>" includes collateral such as training
        materials, video training modules, user surveys, and user assessments
        made available to Customer by Mimecast hereunder.
        <br />
        1.3 If Customer uses the Human Risk Services to upload Customer's own
        written, photographic, and/or videographic collateral, including any
        names, images, or other intellectual property of a third party, ("
        <strong>Collateral</strong>") to Mimecast's platform, then Customer
        agrees that such Collateral: (i) will not infringe on the intellectual
        property rights or any rights related to publicity or the privacy of any
        third party; (ii) will not be defamatory, libelous, threatening or
        harassing in nature; (iii) will not be in violation of any applicable
        laws (including those laws relating to obscenity or pornography); and
        (iv) does not purport to be originated from a governmental agency
        (including, without limitation, agencies related to law enforcement, tax
        or immigration). The requirements of this Section 1.3 are supplemental
        to, and not in replacement of, any other restrictions contained in these
        Human Risk Terms or the Agreement. In the event Mimecast receives notice
        from a third party alleging that any Collateral is in breach of this
        Section 1.3, then Mimecast will, to the extent allowable by law,
        promptly notify Customer, and Customer will immediately remove such
        Collateral from the platform.
        <br />
        1.4 Customer shall, where appropriate, provide notice to all employees
        with regard to the use of the Human Risk Services and obtain all
        relevant acceptance for such use. It is not intended that Customer shall
        collect or process biometric data through the Human Risk Services nor
        make any employment-related decisions of its Permitted Users based
        solely on information obtained via Mimecast Services. Mimecast shall
        bear no responsibility or liability for Customer's use of the Human Risk
        Services in contradiction to this Section.
        <br />
        1.5 Customer will implement and maintain reasonable and appropriate
        controls to ensure that those accessing the Services are permitted to do
        so in accordance with Customer's internal policies and applicable law.
        <br />
        1.6 To the extent that the provision of the Human Risk Services requires
        download and/or installation of software components, such as end-point
        agents, Customer shall ensure that such components are appropriately
        deployed to the Permitted Users, and Customer hereby acknowledges that
        failure to do so may impact access to, and efficacy of, the Services.
        <br />
        1.7 Customer acknowledges that certain Human Risk Services may include a
        managed services offering. By subscribing to a managed service offering,
        Customer is enabling Mimecast to maintain an assigned role in the
        Customer's Mimecast account with limited access to certain Customer Data
        and/or Customer dashboards, necessary for such offering. Mimecast may
        provide the managed service in any jurisdiction where Mimecast maintains
        support personnel.
      </p>
      <p>
        <strong>2. Ownership.</strong>
        <br />
        2.1 <u>Ownership.</u> Customer's rights in the Human Risk Services are
        limited to those expressly stated in these Human Risk Terms. Mimecast
        and its third-party licensors will retain all ownership interest and
        intellectual property rights in and to the Human Risk Services and its
        underlying systems, Mimecast APIs and all Material.
        <br />
        2.2 <u>Licenses</u>. Customer hereby grants to Mimecast all necessary
        rights and licenses to process Customer Data for the purposes of
        providing the Human Risk Services. Customer acknowledges and agrees that
        improving threat detection, analysis, awareness and prevention is
        critical to the functionality of the Human Risk Services. Accordingly,
        Customer grants to Mimecast the necessary rights and licenses to collect
        and process limited data for the maintenance, improvement, and
        enhancement of the Services, including but not limited to, developing
        and improving threat detection, analysis, awareness, and prevention
        capabilities. Further information regarding the details of such
        processing is found in the{" "}
        <a
          href="https://www.mimecast.com/company/mimecast-trust-center/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Trust Center
        </a>{" "}
        and/or any data processing agreement executed by and between the
        parties.
        <br />
        2.3 <u>Aggregated Usage Data.</u> Mimecast processes certain aggregated
        data derived from the Services, including usage data, such as
        utilization statistics, reports, logs and information regarding spam,
        viruses and/or other malware ("Aggregated Usage Data"). Mimecast owns
        all Aggregated Usage Data.
      </p>
      <p>
        <strong>
          3. Disclaimer, Liability and Indemnity.
          <br />
          3.1 TO THE MAXIMUM EXTENT PERMITTED BY LAW AND WITHOUT LIMITING
          MIMECAST'S EXPRESS OBLIGATIONS UNDER THIS AGREEMENT, MIMECAST HEREBY
          DISCLAIMS ALL GUARANTEES, CONDITIONS, WARRANTIES AND REPRESENTATIONS,
          IMPLIED, STATUTORY OR OTHERWISE CONCERNING ANY SERVICES, SOFTWARE,
          DOCUMENTATION OR MATERIALS PROVIDED BY MIMECAST, INCLUDING BUT NOT
          LIMITED TO, THOSE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY
          OF TITLE, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. THE SERVICES DO NOT QUALIFY AS LEGAL OR EXPERT
          ADVICE. CUSTOMER SHOULD CONSIDER WHETHER THE SERVICES ARE APPROPRIATE
          FOR CUSTOMER'S NEEDS, AND WHERE APPROPRIATE, SEEK LEGAL OR EXPERT
          ADVICE. MIMECAST DOES NOT REPRESENT THAT THE SERVICES WILL ACHIEVE
          INTENDED RESULTS, BE UNINTERRUPTED OR ERROR FREE OR MEET CUSTOMER'S
          REQUIREMENTS. CUSTOMER ACKNOWLEDGES AND AGREES THAT REPORTS, GRAPHS,
          ANALYSES OR SIMILAR INFORMATION WHICH MAY BE PROVIDED AS PART OF THE
          SERVICES (COLLECTIVELY, "INFORMATION"), ARE BASED ON INFORMATION KNOWN
          TO MIMECAST AT THE TIME AND PROVIDED FOR CUSTOMER'S INTERNAL BUSINESS
          PURPOSES ONLY. MIMECAST WILL USE REASONABLE EFFORTS TO PROVIDE
          ACCURATE AND UP-TO-DATE INFORMATION BUT MAKES NO GUARANTEE AS TO THE
          ACCURACY OR COMPLETENESS OF THE INFORMATION PROVIDED. 3.2 CUSTOMER
          WILL DEFEND, INDEMNIFY, AND HOLD MIMECAST HARMLESS FROM ANY
          THIRD-PARTY CLAIM RELATING TO CUSTOMER'S BREACH OF SECTIONS 1.3
          AND/OR, 1.4HEREIN. MIMECAST WILL PROVIDE PROMPT WRITTEN NOTICE OF THE
          APPLICABLE CLAIM TO CUSTOMER AND COOPERATE IN CUSTOMER'S DEFENSE, AS
          REASONABLY REQUESTED BY CUSTOMER AND AT CUSTOMER'S EXPENSE. CUSTOMER
          WILL NOT SETTLE ANY SUCH THIRD-PARTY CLAIM IN A MANNER WHICH REQUIRES
          MIMECAST TO ADMIT FAULT OR PAY ANY MONETARY AMOUNTS OF ANY TYPE
          WITHOUT MIMECAST'S EXPRESS PRIOR PERMISSION. CUSTOMER ACKNOWLEDGES AND
          AGREES THAT ANY LIABILITY CAPS INCLUDED IN THE AGREEMENT DO NOT APPLY
          TO CUSTOMER'S OBLIGATIONS UNDER THIS SECTION 3.2.
        </strong>
      </p>
    </TermsAndConditionsWrapper>
  )
}
/* eslint-enable react/no-unescaped-entities, i18next/no-literal-string */
