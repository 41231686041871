import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { getGlobalConfigKey } from "./globalConfig"

// Order matters here for ambiguous language strings -- e.g. if the language is
// "fr" the first "fr-XX" in this list will be picked.
export const supportedLngs = [
  "de-DE",
  "en-US",
  "en-GB",
  "es-ES",
  "fr-FR",
  "fr-CA",
  "it-IT",
  "nl-NL",
  "pt-PT",
]

export const DEFAULT_LOCALE = "en-US"

function getEnabledLngs() {
  switch (getGlobalConfigKey()) {
    case "mimecast_ci":
      return supportedLngs
    case "elevate":
    case "mimecast_cg":
      return supportedLngs
    default:
      return [DEFAULT_LOCALE]
  }
}

let isInitialized: Promise<typeof i18next.t> | null = null

export function initI18n({ lng }: { lng?: string } = {}) {
  if (!isInitialized) {
    isInitialized = i18next
      .use(initReactI18next)
      .use(
        new LanguageDetector(null, {
          // Don't store detected language in cookie or localStorage
          caches: [],
          // Don't use localStorage or cookie to determine language
          order: ["querystring", "navigator", "htmlTag", "path", "subdomain"],
        }),
      )
      .use({
        // Much handier than http back-end since we don't have to worry about CORS
        // here -- webpack can load JS modules by inlining script tags.
        // See https://github.com/i18next/i18next/discussions/1611
        type: "backend",
        read(
          language: string,
          namespace: string,
          callback: (err: Error | null, resources?: any) => void,
        ) {
          import(
            /* webpackChunkName: "[request]" */
            `./locales/${language}/${namespace}.json`
          )
            .then((resources) => {
              callback(null, resources)
            })
            .catch((err) => {
              callback(err)
            })
        },
      })
      .init({
        // debug: true,
        fallbackLng: {
          ...supportedLngs.reduce(
            (fallbacks, locale) => {
              const [lng] = locale.split("-")
              return {
                ...fallbacks,

                // For only language code e.g. "fr", fallback to first matching
                // supported language e.g. "fr-FR". Also specify defauly locale
                // as fallback in case there are any untranslated keys.
                [lng]: fallbacks[lng] ?? [locale, DEFAULT_LOCALE],

                // For explicitly supported languages, fallback to default
                // locale in case there are untranslated keys. Every translation
                // file specifies all keys, so there is no point in falling back
                // from fr-CA to fr-FR for example.
                [locale]: [DEFAULT_LOCALE],
              }
            },
            {} as { [key: string]: string[] },
          ),
          default: [DEFAULT_LOCALE],
        },
        ns: [
          "translation",
          "dashboard",
          "riskAnalysis",
          "individualRiskProfile",
          "watchlists",
          "actionLogs",
        ],
        defaultNS: "translation",
        interpolation: {
          // Let React handle escaping
          escapeValue: false,
        },
        // Specifying supported languages allows language detector to pick the best
        // one even if there isn't an exact match, as well as gate
        supportedLngs: getEnabledLngs(),
        ...(lng ? { lng: lng.replaceAll("_", "-") } : {}),
      })
  }
  return isInitialized
}

// Useful for the few cases where we are loading outside the React context
export function waitForI18n(): Promise<{ t: typeof i18next.t }> {
  return initI18n().then(() => ({ t: i18next.t.bind(i18next.t) }))
}

export function getLocale(): string | undefined {
  // Favour URL param above everything to allow for easier testing. Can remove
  // once CI language switching works for Human Risk
  return (
    new URLSearchParams(location.search).get("lng") ??
    getCILocale() ??
    undefined
  )
}

export function getCILocale(): string | undefined {
  // CI stores the language in these localStorage keys for MFEs to know which
  // language the shell is using
  return (
    localStorage.getItem("language") ??
    localStorage.getItem("gatsby-i18next-language") ??
    localStorage.getItem("i18n.language") ??
    undefined
  )
}
