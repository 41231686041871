import React from "react"
import { createPortal } from "react-dom"
import {
  Modal as _Modal,
  Typography,
} from "@elevate_security/elevate-component-library"
import { ModalWrapper } from "./Modal.styles"

// Wrapper around component library modal to give it more sensible defaults,
// without having to make breaking changes in component library. Prefer to use
// this for all new modals instead of the one in component library.
export function Modal({ className, children, header, ...props }: any) {
  return createPortal(
    <ModalWrapper className={className}>
      <_Modal
        {...props}
        header={
          <Typography.H2 fontWeight="bold" color="900">
            {header}
          </Typography.H2>
        }
      >
        {children}
      </_Modal>
    </ModalWrapper>,
    document.body,
  )
}
