import type { BackendClient } from "./BackendClient"
import moment from "moment"
import fetcher from "@src/services/fetcher"

import { BASE_EP_API_URL, BASES_REPUTATIONS_API_URL } from "@src/constants"
import { getFiltersParam } from "@src/components/EventLogs/utils"
import { getTokenFromLocalStorage } from "@src/utils/configureElevateAuth"

const API_URL = BASE_EP_API_URL()

export class ElevateClient implements BackendClient {
  getEnabledBehaviors() {
    return fetcher
      .get<string[]>(`${API_URL}/scores/get-behaviours`, {
        headers: {
          Authorization:
            // Override the interceptor auth header. For this endpoint, when
            // using the managerToken, take exactly what is in localStorage so
            // we get the right prefix. i.e. here we might need Token if we're
            // going to ep-server with a Pulse token. In most cases we want
            // Bearer (e.g. for an admin going to reputation service)
            getTokenFromLocalStorage(),
        },
      })
      .then(({ data }) => data)
  }

  getBehaviorEventsCount(
    action: string,
    { months, es_person_id }: { months?: number; es_person_id?: string } = {},
  ): Promise<{ data: { total_elements: number } }> {
    const filteredAction =
      action === "attack_rwp"
        ? "real_world_phishing"
        : action.replace("attack_", "")

    const actionTypes =
      action === "attack_rwp"
        ? ["real-world-phishing-blocked", "real-world-phishing-delivered"]
        : []

    const startDate = `${moment()
      .subtract(months, "month")
      .format("YYYY-MM")}-01`

    const params = {
      filters: getFiltersParam(
        {
          action: [filteredAction],
          date: [startDate],
          action_event: actionTypes,
        },
        "",
      ),
      order_by: "date",
      page_number: 0,
      page_size: 1,
    }

    const ENDPOINT_URL = es_person_id
      ? `/analysis/individuals/events/${es_person_id}`
      : `/organization/actions/${filteredAction}/events`

    return fetcher
      .get<{
        data: { total_elements: number }
      }>(`${BASES_REPUTATIONS_API_URL}${ENDPOINT_URL}`, { params })
      .then(({ data }) => data)
  }
}
