import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { DEFAULT_LOCALE } from "@src/i18n"

export function useI18nResourceBundle(namespace: string) {
  const { i18n } = useTranslation()

  // Fail-safe fallback to default locale if for some reason the current
  // language doesn't have translations. Should never be hit as long as we are
  // specifying supported languages explicitly in i18next config.
  return useMemo(
    () =>
      i18n.getResourceBundle(
        i18n.resolvedLanguage ?? DEFAULT_LOCALE,
        namespace,
      ) ?? i18n.getResourceBundle(DEFAULT_LOCALE, namespace),
    [i18n, namespace],
  )
}
