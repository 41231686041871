export const filterActionFactorData = (
  activeActions,
  data,
  { predicate = () => false } = {},
) => {
  return data
    ? data.filter((el) => {
        const dashedKey = el.key.replaceAll("_", "-")
        return (
          (activeActions?.includes(dashedKey) ||
            predicate(dashedKey, activeActions)) &&
          el.key !== "overall" &&
          el.key !== "optional_activities" &&
          el.current !== null
        )
      })
    : []
}

export const arraySelector = (score, array) => {
  if (score < 2) return array[0]
  if (score < 4) return array[1]
  if (score < 6) return array[2]
  if (score < 8) return array[3]
  if (score <= 10) return array[4]
  return array[0]
}
