import PropTypes from "prop-types"
import { truncateNumber } from "@src/utils/numbers/utils"
import { getBehaviourEventsCount } from "@src/services/apis/reputation"
import { useRequest } from "ahooks"
import React from "react"
import { Badge } from "@elevate_security/elevate-component-library"
import { useTranslation } from "react-i18next"

export const EventBadge = ({ id, person_nid, months, eventCountOverride }) => {
  const { t } = useTranslation("dashboard")

  const { data, loading } = useRequest(
    () => {
      return getBehaviourEventsCount(id, months, { es_person_id: person_nid })
    },
    {
      // If an override is passed in, no need to fetch the count here, so only
      // fetch if the override is not defined
      ready: typeof eventCountOverride === "undefined",
      refreshDeps: [months],
      formatResult: (response) => {
        return response?.data
      },
    },
  )

  const eventTotal = eventCountOverride ?? data?.total_elements

  return (
    <Badge
      text={
        loading
          ? t("eventBadge.loading")
          : truncateNumber(eventTotal ?? 0) + " " + t("eventBadge.events")
      }
      theme="eventsgrey"
      shape="smallboldround"
    />
  )
}

EventBadge.propTypes = {
  id: PropTypes.string.isRequired,
  person_nid: PropTypes.string,
  months: PropTypes.number.isRequired,
  eventCountOverride: PropTypes.number,
}
