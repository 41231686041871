import fetcher from "@src/services/fetcher"
import { getErrorStatus } from "@src/services/fetcher/utils"
import { BASE_EP_API_URL, BASES_REPUTATIONS_API_URL } from "@src/constants"
import moment from "moment"
import { currentDate, subtractYear } from "@src/utils/dates"
import { isEmpty } from "@src/utils/string"
import { fetchActionPerformance } from "./fetchActionPerformance"
import backendClient from "@src/backend-client"

const API_URL = BASE_EP_API_URL()

const filterColumnValues = (filters) => {
  const filtersArray = filters?.split(" AND ").map((filter) => filter.trim())
  if (!filtersArray) return ""

  return filtersArray.map((filter) => filter.trim()).join(" AND ")
}

export async function getReputationRisksByPersonId(es_person_id) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/risks/${es_person_id}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getAttackExplanation(es_person_id, params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/risks/${es_person_id}/attack_explanation`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getRecommendation() {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/recommendations`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getRisksAnalysisData(params) {
  params.filters = filterColumnValues(params.filters)
  try {
    const url = params.url
    delete params.url
    return await fetcher.get(url, {
      params: params,
    })
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getEventsLogFromReputations(es_person_id, params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/individuals/events/${es_person_id}`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getDashboardMostAttacked(params) {
  try {
    return await fetcher.get(`${BASES_REPUTATIONS_API_URL}/analysis/risks`, {
      params,
    })
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function downloadEventsLogFromReputations(
  es_person_id,
  params,
  orderBy,
) {
  let setParams = ""
  if (params !== "" && params !== null) {
    setParams = `filters=${params}&`
  }
  if (orderBy !== "" && orderBy !== null) {
    setParams = `${setParams}order_by=${orderBy}&`
  }
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/individuals/events/${es_person_id}?${setParams}download=True`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getActionAbleInsight() {
  try {
    return await fetcher.get(`${BASES_REPUTATIONS_API_URL}/analysis/insights`)
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getActionableEventLog(insight, params) {
  // org_id, params
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/insights/${insight}/events`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}
export async function getSpecificActionAbleInsight(insight) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/insights/${insight}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}
export async function getAdditionalStats(insight) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/insights/${insight}/additional_stats`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getActionableListOfPeople(insight, params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/insights/${insight}/individuals`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function patchGroupsToMembers(group_key, data) {
  try {
    return await fetcher.patch(
      `${BASES_REPUTATIONS_API_URL}/groups/${group_key}`,
      data,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getRisksKeys() {
  try {
    return await fetcher.get(`${BASES_REPUTATIONS_API_URL}/analysis/risks/keys`)
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getAuditLogs(es_person_id, params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/individuals/audit/${es_person_id}`,
      {
        params,
      },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getNewReputationRisks() {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/risk_scores`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getOrganizationReputationFactor(factorType) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/${factorType}_count`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getScoreBreakDownActionData(action) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/actions/${action}/score_breakdown`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getDashboardRiskiest(riskType) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/riskiest_${riskType}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getDashboardHistoricalRiskScores(months) {
  const hasMonths = months
    ? `?start_date=${moment().subtract(months, "month").format("YYYY-MM")}-01`
    : ""
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/historical_risk_scores${hasMonths}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getDashboardHistoricalEventCount(months) {
  const hasMonths = months ? `?months=${months}` : ""
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/events_count${hasMonths}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function downloadIndividual(params, orderBy, advancedFilters) {
  let setParams = ""
  if (params || advancedFilters) {
    setParams = advancedFilters
      ? `advanced_filters=${encodeURIComponent(JSON.stringify(advancedFilters))}&`
      : `filters=${encodeURIComponent(params)}&`
  }
  if (orderBy !== "" && orderBy !== null) {
    setParams = `${setParams}order_by=${orderBy}&`
  }
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/risks?${setParams}download=True`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function downloadDepartment(params, orderBy) {
  let setParams = ""
  if (params !== "" && params !== null) {
    setParams = `filters=${params}&`
  }
  if (orderBy !== "" && orderBy !== null) {
    setParams = `${setParams}order_by=${orderBy}&`
  }
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/departments/risks?${setParams}download=True`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}
export async function downloadLocation(params, orderBy) {
  let setParams = ""
  if (params !== "" && params !== null) {
    setParams = `filters=${params}&`
  }
  if (orderBy !== "" && orderBy !== null) {
    setParams = `${setParams}order_by=${orderBy}&`
  }
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/locations/risks?${setParams}download=True`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}
export async function downloadActions(params, orderBy, action) {
  if (orderBy !== "" && orderBy !== null) {
    params = `${params}&order_by=${orderBy}`
  }
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/actions/${action}/events?filters=${params}&download=True`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}
export async function getEventLogModal(action, params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/actions/${action}/events`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getLatestEventsLog(action, params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/actions/${action}/latest_events`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getActionsData(action, months) {
  const hasMonths = months ? `?months=${months}` : ""
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/actions/${action}${hasMonths}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getActionPerformance(
  action,
  months,
  { es_person_id } = {},
) {
  try {
    return await fetchActionPerformance(action, { months, es_person_id })
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getSpecificActionAbleInsightDataTrends(insight) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/insights/${insight}/data_trends`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getSpecificActionAbleInsightHistoricalData(insight) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/insights/${insight}/historical_data`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getSpecificActionAbleInsightPlaybooks(insight) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/insights/${insight}/playbooks`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getPolicyDetailsWithRules(policy_id) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/${policy_id}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getPolicyStats(params, person_id) {
  const endPointParams = person_id
    ? `/${person_id}?start_date=${params}`
    : `?start_date=${params}`

  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/stats${endPointParams}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getIRPPolicyStats(es_person_id, startDate) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/stats/${es_person_id}?start_date=${startDate}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getServicesList() {
  try {
    return await fetcher.get(`${BASES_REPUTATIONS_API_URL}/policies/services`)
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getHistoricalActionScores({
  start_date = subtractYear({}),
  end_date = currentDate({}),
}) {
  try {
    const params = {
      start_date,
      end_date,
    }
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/historical_action_scores`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getIndividualHistoricalActionsScore({
  start_date = subtractYear({}),
  person_nid,
}) {
  try {
    const params = {
      start_date,
    }
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/individuals/scores/historical/action/${person_nid}`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getIndividualHistoricalRisksScore({
  start_date,
  person_nid,
}) {
  try {
    const params = {
      start_date,
    }
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/individuals/scores/historical/risk/${person_nid}`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getIndividualScoresHistoricalActionScores({
  start_date,
  profileId,
}) {
  return getIndividualHistoricalActionsScore({
    start_date,
    person_nid: profileId,
  })
}

function getFilterParamsString(filters, advancedFilters) {
  const filterParams = filters ? `?filters=${filters}` : ""
  const advancedFilterParams = advancedFilters
    ? `?advanced_filters=${JSON.stringify([advancedFilters])}`
    : ""
  // We can't send both filters and advanced_filters, so we arbitrarily pick
  // filters here if for some reason we receive both
  return filterParams || advancedFilterParams
}

export async function createNewGroupWithFilters(
  filters,
  payload = {},
  advancedFilters = "",
) {
  const params = getFilterParamsString(filters, advancedFilters)
  try {
    return await fetcher.post(
      `${BASES_REPUTATIONS_API_URL}/analysis/risks/create_group${params}`,
      {
        group_name: payload.group_name,
        do_not_target_ids: payload.do_not_target_ids,
      },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function addMembersToGroupWithFilters(
  filters,
  payload = {},
  advancedFilters = "",
) {
  const params = getFilterParamsString(filters, advancedFilters)
  try {
    return await fetcher.post(
      `${BASES_REPUTATIONS_API_URL}/analysis/risks/add_members_to_group${params}`,
      {
        group_id: payload.group_id,
        do_not_target_ids: payload.do_not_target_ids,
        target_ids: payload.target_ids,
      },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getIndividualsRulesAuditLogs(es_person_id, params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/individuals/rules_audit_logs/${es_person_id}`,
      {
        params,
      },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

//This fetches data for the toggle drop down in actions (Azure Ad)
export async function getAzureAdDropDownElements({
  search,
  limit = 25,
  offset = 0,
}) {
  try {
    const response = await fetcher.get(
      `${API_URL}/intsync/groups/?vendor=azuread&search=${search}&limit=${limit}&offset=${offset}`,
    )
    return response
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getAzureADApplications({
  search,
  page_size = 25,
  page_number = 0,
  id__in = "",
}) {
  const params = {
    search,
    limit: page_size,
    offset: page_number * page_size,
  }

  if (!isEmpty(id__in)) {
    params.app_id__in = id__in
  }

  try {
    const response = await fetcher.get(
      `${API_URL}/intsync/azuread/enterprise-applications/`,
      {
        params,
      },
    )
    return response
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getOutboundTemplateAttributes(categories = "") {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/outbound-template-attributes?categories=${categories}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getCiscoDuoDropDownElements(params) {
  try {
    const response = await fetcher.get(
      `${API_URL}/intsync/groups/?vendor=ciscoduo`,
      {
        params,
      },
    )
    return response
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getSailPointWorkFlowData(params) {
  try {
    return await fetcher.get(`${API_URL}/intsync/sailpoint/get-workflows/`, {
      params,
    })
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getSailPointCredentials({ workflow_id }) {
  try {
    return await fetcher.get(
      `${API_URL}/intsync/sailpoint/get-workflow-credentials/?workflow_id=${workflow_id}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getManagerTeam(params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/manager/team`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getManagerScore(params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/manager/scores`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getTeamTrends(params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/manager/team_score_trends`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getCiscoDuoApplicationPolicies(params) {
  try {
    const response = await fetcher.get(
      `${API_URL}/intsync/cisco-duo/get-application-policies/`,
      {
        params,
      },
    )
    return response
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getCiscoDuoApplications(params) {
  try {
    const response = await fetcher.get(
      `${API_URL}/intsync/cisco-duo/get-applications/`,
      {
        params,
      },
    )
    return response
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getIndividualsGroupedRulesAuditLogs(
  es_person_id,
  params,
) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/individuals/grouped_rules_audit_logs/${es_person_id}`,
      {
        params,
      },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getCrowdstrikeFalconIncidentTags(params) {
  try {
    return await fetcher.get(
      `${API_URL}/intsync/crowdstrike-falcon/get-incident-tags/`,
      {
        params,
      },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getAzureADApplicationsRoles(params) {
  try {
    const response = await fetcher.get(
      `${API_URL}/intsync/azuread/applications/roles/`,
      {
        params,
      },
    )
    return response
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export function getBehaviours() {
  return backendClient.getEnabledBehaviors()
}

export function getBehaviourEventsCount(action, months, { es_person_id } = {}) {
  return backendClient.getBehaviorEventsCount(action, { months, es_person_id })
}
