import React from "react"
import { useTranslation } from "react-i18next"

import { SpacingY } from "@src/components/SpacingY"
import { SpacingX } from "@src/components/SpacingX"
import VideoPreviewImage from "@src/assets/human-risk-video-preview.png"
import { LearnMoreButton } from "../HumanRiskBehaviors/LearnMoreButton"
import { IntegrationsHubButtonLink } from "../HumanRiskBehaviors/IntegrationsHubButtonLink"
import { Modal } from "@src/components/Modal"

export function EducationModal({ showModal = false, onClose = () => {} }) {
  const { t } = useTranslation("dashboard")

  return (
    <Modal
      onClose={onClose}
      isOpen={showModal}
      header={t("educationCard.modalHeader")}
      rightButtons={[
        <SpacingX key="buttons">
          <LearnMoreButton to="https://www.mimecast.com/why-mimecast/" />
          <IntegrationsHubButtonLink />
        </SpacingX>,
      ]}
    >
      <SpacingY size="md">
        {/* TODO: embed this video */}
        <a
          href="https://vimeo.com/1055685132/77bb89582a"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={VideoPreviewImage} />
        </a>
        <div>{t("educationCard.modalDetails1")}</div>
        <div>{t("educationCard.modalDetails2")}</div>
      </SpacingY>
    </Modal>
  )
}
