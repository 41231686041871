import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { setTimePeriodValue } from "@src/services/redux/reputations/actions"
import TimePeriod from "@src/scenes/HumanRiskDashboard/components/TimePeriod/TimePeriod"

export function TimePeriodSelect() {
  const dispatch = useDispatch()

  const timePeriodValue =
    useSelector((state) => state.get("reputationsReducer"))
      ?.time_period_value || ""

  return (
    <TimePeriod
      initialValue={timePeriodValue}
      timePeriodHandler={(selected) =>
        dispatch(setTimePeriodValue(selected.value))
      }
    />
  )
}
