import React, { useEffect, useMemo, useState } from "react"
import { Table, Pagination } from "antd"
import { ScoreBreakdownTableWrapper } from "@src/scenes/ManagerDashboard/scenes/Session/styles"
import {
  EXPENDED_ICONS,
  getColumns,
  tableFooter,
} from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/getScoreBreakColumns"
import { INIT_MANAGER_DRAWER } from "@src/scenes/HumanRiskDashboard/constants"
import { useDispatch, useSelector } from "react-redux"
import { getManagerOverview } from "@src/scenes/ManagerDashboard/services/redux/selectors"
import ScoreDetailsDrawer from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/ScoreDetailsDrawer"
import { managerDummyData } from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/utils"
import { useParams } from "react-router-dom"
import { getWhoAMI } from "@src/services/selectors/visionSelectors"
import {
  setManagerTeam,
  setManagerTeamLoader,
  setChildManagerTeamData,
  removeChildManagerTeamData,
} from "@src/scenes/ManagerDashboard/services/redux/actions"

function ScoreBreakTable({ filters, isManagerView }) {
  const [drawer, setDrawer] = useState(INIT_MANAGER_DRAWER)
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 })
  const { managerInfo, managerTeamData, totalTeamMembers, managerTeamLoader } =
    useSelector(getManagerOverview)
  const { person_nid } = useParams()
  const whoAmI = useSelector(getWhoAMI)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setManagerTeamLoader(true))
    dispatch(
      setManagerTeam({
        filters,
        person_nid,
        managerInfo,
        page_number: pagination.current,
        page_size: pagination.pageSize,
      }),
    )
  }, [pagination])

  const columns = useMemo(
    () =>
      getColumns(
        managerTeamLoader,
        managerInfo,
        setDrawer,
        whoAmI,
        isManagerView,
      ),
    [managerTeamLoader, managerInfo, isManagerView],
  )

  const handleExpand = async (
    dispatch,
    record,
    expanded,
    onExpand,
    isChecked,
  ) => {
    expanded.stopPropagation()
    const firstName = record.full_name.split(" ")[0] || ""
    if (!isChecked) {
      dispatch(
        setChildManagerTeamData({
          es_person_id: record?.es_person_id || "",
          key: record.key,
          rowData: { ...record, individual: `${firstName}'s Team` },
          page_size: pagination.pageSize,
          page_number: pagination.current,
          managerInfo: managerInfo,
        }),
      )
    } else {
      dispatch(
        removeChildManagerTeamData({
          es_person_id: record?.es_person_id || "",
        }),
      )
    }
    onExpand(record, expanded)
  }

  const handleChangeTable = async (page, pageSize) => {
    setPagination({ current: page, pageSize })
  }

  return (
    <>
      <ScoreBreakdownTableWrapper>
        <Table
          columns={columns}
          dataSource={
            managerTeamLoader ? managerDummyData : managerTeamData || []
          }
          onChange={handleChangeTable}
          pagination={false}
          scroll={{ y: "calc(100vh - 310px)" }}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => {
              if (record?.is_manager) {
                if (record.isLoading) {
                  return EXPENDED_ICONS.loading({
                    managerTeamLoader,
                    record,
                  })
                }
                return EXPENDED_ICONS.caret({
                  managerTeamLoader,
                  record,
                  onExpand,
                  expanded,
                  handleExpand,
                  dispatch,
                })
              }
            },
          }}
        />

        <Pagination
          showSizeChanger
          onChange={handleChangeTable}
          defaultCurrent={pagination?.current || 1}
          defaultPageSize={pagination?.pageSize || 100}
          current={pagination?.current || 1}
          total={totalTeamMembers || 0}
          pageSizeOptions={[100]}
          showTotal={(total, [from, to]) => {
            return tableFooter({ from, to, total })
          }}
        />
      </ScoreBreakdownTableWrapper>
      {drawer.isOpen && (
        <ScoreDetailsDrawer drawer={drawer} setDrawer={setDrawer} />
      )}
    </>
  )
}

export default ScoreBreakTable
