declare const GLOBAL_CONFIG: GlobalConfigType

import elevate from "./elevate"
import _mimecast from "./mimecast"
import type { GlobalConfig } from "./GlobalConfig"

const mimecast = {
  ..._mimecast,
}

type GlobalConfigType = "elevate" | "mimecast_cg" | "mimecast_ci"

const configs: Record<GlobalConfigType, GlobalConfig> = {
  elevate,
  // CI is identical to CG for now. If they diverge, make separate configs.
  mimecast_cg: mimecast,
  mimecast_ci: mimecast,
} as const

export function getGlobalConfig<K extends keyof GlobalConfig>(
  key: K,
): GlobalConfig[K] {
  const config = configs[GLOBAL_CONFIG]
  if (!Object.prototype.hasOwnProperty.call(config, key)) {
    throw new Error(`Config property missing ${key}`)
  }
  return config[key]
}

export function getGlobalConfigKey(): GlobalConfigType {
  return GLOBAL_CONFIG
}
