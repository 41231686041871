import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { ActionFactorScoreChartContainer } from "./styles"

import {
  getDashboardMostAttacked,
  getHistoricalActionScores,
} from "@src/services/apis/reputation"
import {
  getDashboardFactorGraphsData,
  getOrganizationRiskLevelsNew,
  setHierachicalData,
} from "@src/services/redux/reputations/actions"
import HumanRisksIndividualsProvider, {
  AttackedIndividualsProvider,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/HumanRiskIndividual/HumanRisksIndividualsProvider"
import SideDrawer from "@src/scenes/IndividualRiskAnalysis/components/SideDrawer"
import { closeSideDrawer } from "@src/services/redux/analysisIndividuals/actions"
import LatestEventModal from "@src/scenes/IndividualRiskAnalysis/components/SideDrawer/LatestEvents/LatestEventModal"
import { HumanRiskBehaviorsSection } from "../HumanRiskBehaviors"
import { SpacingY } from "@src/components/SpacingY"
import { AttackFactorOverTime } from "../TrendingOverTime/TrendingOverTime"
import { useRequest } from "ahooks"
import { getFiltersParam } from "@src/components/EventLogs/utils"
import { ScoreProgressBar } from "@elevate_security/elevate-component-library"
import { getGlobalConfig } from "@src/globalConfig"
import { roundUserFacingScore } from "@src/utils/numbers/roundUserFacingScore"
import { useTranslation } from "react-i18next"

export function ActionFactorSideDrawer({
  keyFactor,
  currentFactorScore,
  sideDrawerTrendValue,
}) {
  const dispatch = useDispatch()

  const closeDrawer = () => {
    dispatch(closeSideDrawer())
  }

  return (
    <>
      {createPortal(
        <SideDrawer
          count={0}
          onClose={closeDrawer}
          onEnroll={() => {}}
          onRecommendedEnroll={() => {}}
          onPageChange={() => {}}
          showHackersmind={false}
          showPulse={false}
          showReflex={false}
          actionFactorScoreFlag={true}
          keyFactor={keyFactor}
          currentFactorScore={currentFactorScore}
          sideDrawerTrendValue={sideDrawerTrendValue}
          showInsightConditions={true}
        />,
        document.body,
      )}
      {/* Modal can only be opened from side drawer, which is why we locate it here */}
      <LatestEventModal />
    </>
  )
}

export function ActionFactorSection({ onOpenSideDrawer }) {
  const dispatch = useDispatch()

  const reputations = useSelector((state) => state.get("reputationsReducer"))
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getActionFactorData = async () => {
      const start_date = moment(moment.now())
        .subtract(3, "months")
        .format("YYYY-MM-DD")
      const end_date = moment(moment.now()).format("YYYY-MM-DD")
      const params = {
        start_date,
        end_date,
      }

      let { data } = await getHistoricalActionScores(params)

      if (!data) return

      if (!data.overall?.length) {
        // If we don't have any data for the last three months, fetch data for
        // the last 13 months Most active orgs will have the last three months
        // of data, but many staging orgs don't, or have problems with scoring,
        // so we fall back here until we can sort out the data in those orgs.
        data = (
          await getHistoricalActionScores({
            ...params,
            start_date: moment(moment.now())
              .subtract(13, "months")
              .format("YYYY-MM-DD"),
          })
        ).data
      }

      const most_available_data_index = data?.overall?.findLastIndex(
        (el) => el.score != null,
      )

      const risks = Object.entries(data).map(([key, items]) => {
        let mostAvailableScore = items?.[most_available_data_index]

        if (mostAvailableScore?.score === null) {
          mostAvailableScore = items?.findLast((item) => item.score !== null)
        }

        return {
          key,
          prev: mostAvailableScore?.previous_score,
          current: mostAvailableScore?.score,
        }
      })
      risks.sort((a, b) => b.current - a.current)

      dispatch(setHierachicalData(risks))
    }
    setLoading(true)
    getActionFactorData().finally(() => {
      setLoading(false)
    })
  }, [dispatch])

  return (
    <ActionFactorScoreChartContainer>
      <HumanRiskBehaviorsSection
        actionFactorScoreData={reputations.hierachicalData}
        onOpenSideDrawer={onOpenSideDrawer}
        loading={loading}
      />
    </ActionFactorScoreChartContainer>
  )
}

export function AttackFactorSection({ onOpenSideDrawer }) {
  const dispatch = useDispatch()
  const { t } = useTranslation("dashboard")

  const reputations = useSelector((state) => state.get("reputationsReducer"))

  useEffect(() => {
    if (reputations.factor_graph_loader) {
      dispatch(getDashboardFactorGraphsData())
    }
  }, [dispatch, reputations.factor_graph_loader])

  const dataList = reputations.hierachicalData.filter((data) =>
    data.key.startsWith("attack_"),
  )

  const orgAttackFactorScore =
    reputations?.trend_historical_risk_scores?.attack?.findLast(
      ({ score }) => score !== null,
    )?.score

  // If there is no org attack score present, we assume that org attack factor
  // is not enabled, regardless of what the global config says
  const showOrgAttackFactor =
    getGlobalConfig("ENABLE_ORG_ATTACK_FACTOR") &&
    typeof orgAttackFactorScore === "number"

  return (
    <SpacingY size="md">
      {showOrgAttackFactor && (
        <ScoreProgressBar
          score={roundUserFacingScore(orgAttackFactorScore || 0.1)}
          size="medium"
          bodyPadding="30px 12px 10px"
          scoreLabelFormatter={(label) => {
            return t(`humanRiskScoreCard.scoreGauge.${label}`)
          }}
        />
      )}
      <AttackFactorOverTime showOrgAttackFactor={showOrgAttackFactor} />
      <HumanRiskBehaviorsSection
        actionFactorScoreData={dataList}
        title=""
        isAttackFactor={true}
        scoreType="text"
        onOpenSideDrawer={onOpenSideDrawer}
      />
    </SpacingY>
  )
}

export function RiskiestEntitiesSection() {
  const reputations = useSelector((state) => state.get("reputationsReducer"))

  const dispatch = useDispatch()

  const loader = reputations.apiLoader

  useEffect(() => {
    if (loader) dispatch(getOrganizationRiskLevelsNew())
  }, [loader, dispatch])

  return <HumanRisksIndividualsProvider />
}

export function MostAttackedSection() {
  const formatResult = (response) => {
    return response?.data?.results
  }
  const { data = [], loading } = useRequest(
    ({ ...params }) => {
      const args = {
        filters: getFiltersParam({}),
        order_by: "attack_factor desc",
        page_number: 1,
        page_size: 10,
        ...params,
      }

      return getDashboardMostAttacked(args)
    },
    { formatResult },
  )

  return <AttackedIndividualsProvider data={data} loading={loading} />
}
