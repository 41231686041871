import { THEME_PRIMARY } from "@src/theme"
import styled, { keyframes } from "styled-components"

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const SpinnerBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    ` rgba(255, 255, 255, ${props.opacity || 0.8})`};
  transition: opacity 300ms;
  opacity: 1;
  z-index: 1010;

  &.unloading {
    opacity: 0;
  }

  max-height: 100vh;
`

export const Spinner = styled.div`
  display: inline-block;
  position: absolute;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: ${(props) => props.margin || "auto"};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    margin: 0;
    border: ${(props) => props.border} solid ${THEME_PRIMARY};
    border-radius: 50%;
    animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${THEME_PRIMARY} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`
