import React, { useEffect, useCallback, useRef } from "react"
import { useDebounceFn } from "ahooks"
import { isScrolledToBottom } from "./isScrolledToBottom"

export function ScrollableDiv({
  children,
  onScrollBottom,
  ...props
}: React.PropsWithChildren<
  { onScrollBottom: () => void } & React.HTMLAttributes<HTMLDivElement>
>) {
  const scrollRef = useRef<HTMLDivElement | null>(null)

  const checkHasScrolledToBottom = useCallback(() => {
    const contentDiv = scrollRef.current
    if (!contentDiv) {
      return
    }
    if (isScrolledToBottom(contentDiv)) {
      onScrollBottom()
    }
  }, [onScrollBottom, scrollRef])

  useEffect(() => {
    // Check right away in case for some reason all the terms fit on the screen
    // without needing to scroll
    checkHasScrolledToBottom()
  }, [checkHasScrolledToBottom])

  const { run: debouncedCheckHasScrolledToBottom } = useDebounceFn(
    checkHasScrolledToBottom,
    { wait: 250 },
  )

  return (
    <div
      ref={scrollRef}
      onScroll={debouncedCheckHasScrolledToBottom}
      {...props}
    >
      {children}
    </div>
  )
}
