declare const REACT_ROUTER_BASE_URL: string

import React from "react"
import styled from "styled-components"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { datadogRum } from "@datadog/browser-rum"
import { AwsRum } from "aws-rum-web"
import { withTranslation, WithTranslation } from "react-i18next"

import VisionContainer, { VisionContainerProps } from "./VisionContainer"
import Icon from "../components/Icon"
import ToastsManager from "@src/components/ToastsManager"
import ManagerDashboard from "@src/scenes/ManagerDashboard"
import ScrollToTop from "@src/components/ScrollToTop"
import { setWhoAmI } from "@src/services/actions"
import { connect } from "react-redux"
import { THEME_PRIMARY, THEME_HOVER } from "@src/theme"
import { getGlobalConfig } from "@src/globalConfig"
import { RouteWatcher } from "./RouteWatcher"
import Sidebar from "@src/scenes/ManagerDashboard/components/Sidebar"

export type VisionProps = {
  logindata: {
    is_admin: boolean
    is_manager: boolean
    id: string
    full_name: string
    email: string
  }
  setWhoAmI: (logindata: VisionProps["logindata"]) => void
  companyInfoOverride?: VisionContainerProps["companyInfoOverride"]
  observeRouteChanges: boolean
  awsRum?: AwsRum | null
} & WithTranslation

// ui-elevateplatform requires this ID to be set for some styling; it's horrible
// but we leave it for backwards compatiblity
const VisionIdWrapper = styled.div.attrs({ id: "vision-dashboard" })`
  background-color: rgb(250, 250, 250);

  // Here we force some mimecast style overrides to be applied to all of vision.
  // Eventually they will be applied to the whole app, but this lets us do it
  // incrementally for now.
  a {
    color: ${THEME_PRIMARY};
    :hover,
    :focus {
      color: ${THEME_HOVER};
    }
  }

  // Copied from ui-ep, this is a tricky one to get rid of
  a[class^="ButtonLink__"] {
    color: ${THEME_PRIMARY} !important;
    :hover {
      text-decoration: underline !important;
      color: ${THEME_HOVER} !important;
    }
  }
`

class Vision extends React.Component<VisionProps> {
  state = {
    hasError: false,
  }

  componentDidMount() {
    const { setWhoAmI, logindata } = this.props

    if (logindata) {
      setWhoAmI(logindata)
    }
  }

  setError = (hasError: boolean) => {
    this.setState({ hasError })
  }

  componentDidCatch(error: Error, info: any) {
    // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
    const renderingError: any = new Error(error.message)
    renderingError.name = "ReactRenderingError"
    renderingError.stack = info.componentStack
    renderingError.cause = error

    datadogRum.addError(renderingError)

    this.setState({ hasError: true })
  }

  render() {
    const { hasError } = this.state
    const css = `
      .center {
        margin: auto;
        padding: 10px;
        width: 50%;
      }
      .center-text {
        display: table;
        margin: 0 auto;
      }
      .error-header-text{
        font-family: inherit;
        color: #1D1E1F;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 5px;
      }
      .error-text {
        font-family: inherit;
        color: #565D66;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
      }
      .error-large-width {
        width: 425px;
      }
      .error-text-top {
        margin-top: 15px;
      }
    `

    const { t } = this.props

    return (
      <>
        {hasError ? (
          <VisionIdWrapper>
            <style>{css}</style>
            <div className="error-width">
              <div className="center error-large-width">
                {getGlobalConfig("ELLIE") && <Icon name="error" />}
                <div className="error-large-width error-text-top">
                  <div className="center-text error-header-text">
                    {t("catchAllErrorMessage")}
                  </div>
                  <div className="center-text error-text">
                    {t("catchAllErrorDetail")}
                  </div>
                </div>
              </div>
            </div>
          </VisionIdWrapper>
        ) : (
          <Router basename={REACT_ROUTER_BASE_URL}>
            <RouteWatcher
              pollForChanges={this.props.observeRouteChanges}
              awsRum={this.props.awsRum}
            />
            <ToastsManager fixed />
            <Switch>
              <Route
                path="/vision2/manager-dashboard/:person_nid?"
                component={ManagerDashboard}
              />
              {/* Below route is hacks for viewing IRP as a non-admin manager */}
              <Route
                path="/vision2/:profileId/profile"
                component={({ history, location }: any) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        position: "static",
                        padding: 0,
                      }}
                    >
                      <Sidebar location={location} history={history} />
                      <VisionIdWrapper
                        style={{
                          position: "absolute",
                          left: "270px",
                          height: "100%",
                          overflow: "auto",
                          right: 0,
                          padding: "10px 20px",
                        }}
                      >
                        <VisionContainer
                          setError={this.setError}
                          bootstrap={false}
                        />
                      </VisionIdWrapper>
                    </div>
                  )
                }}
              ></Route>
              <Route path="">
                <VisionIdWrapper>
                  <ScrollToTop />
                  <VisionContainer
                    companyInfoOverride={this.props.companyInfoOverride}
                    setError={this.setError}
                  />
                </VisionIdWrapper>
              </Route>
            </Switch>
          </Router>
        )}
      </>
    )
  }
}

const mapDispatchToProps = {
  setWhoAmI,
}

export default connect(null, mapDispatchToProps)(withTranslation()(Vision))
