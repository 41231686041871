import React from "react"
import {
  IndividualHumanRiskScoreInfoSection,
  AttackScoreExplanation,
} from "./components"
import { darklyGetFlag } from "@src/utils/darkly"
import StatusCheck from "@src/scenes/IndividualProfilePage/ProfileInfo/components/StatusCheck/StatusCheck"
import { DashboardCard } from "@src/components/DashboardCard"
import { SpacingY } from "@src/components/SpacingY"
import { useIndividualProfileState } from "../IndividualProfileContextProvider"
import { useRequest } from "ahooks"
import { getIndividualScoresHistoricalActionScores } from "@src/services/apis/reputation"
import { getCompanyInfo } from "@src/services/redux/company/selectors"
import { useParams } from "react-router-dom"
import moment from "moment"
import { SpacingX } from "@src/components/SpacingX"
import ActionLog from "../ActionLog/ActionLog"
import { IndividualRiskProfileAttackFactorInfoTooltip } from "@src/components/DashboardInfoTooltips"
import { useTranslation } from "react-i18next"
import { useIsBasicMimecastCustomer } from "@src/utils/useIsBasicMimecastCustomer"

export default function ProfileInfo({
  onSwitchToEventsTab,
  onSwitchToActionlogsTab,
  isManagerView,
}) {
  const { t } = useTranslation("individualRiskProfile")
  const vcDemo = darklyGetFlag("show-vc-demo-features")
  const { individualData } = useIndividualProfileState()
  const person_nid = individualData.es_person_id
  const { shortname: organization_nid } = getCompanyInfo()
  const { profileId } = useParams()
  const startDate = `${moment().subtract(3, "month").format("YYYY-MM")}-01`

  const isBasicMimecastCustomer = useIsBasicMimecastCustomer()

  const { data = [] } = useRequest(
    () =>
      getIndividualScoresHistoricalActionScores({
        organization_nid: organization_nid,
        profileId,
        start_date: startDate,
        person_nid,
      }),
    {
      refreshDeps: [],
      defaultPageSize: 25,
      formatResult: (response) => response.data,
    },
  )

  const buildLatestScoreData = (key) => {
    const latestData = data[key].findLast(({ score }) => score !== null)
    return {
      key: key,
      current: latestData?.score || 0,
      prev: latestData?.previous_score || 0,
    }
  }

  const attackData = Object.keys(data)
    .filter((key) => {
      return key.startsWith("attack_")
    })
    .map(buildLatestScoreData)

  const actionData = Object.keys(data)
    .filter((key) => {
      return !key.startsWith("attack_") && key !== "attack" && key !== "overall"
    })
    .map(buildLatestScoreData)

  return (
    <SpacingY size="md">
      <IndividualHumanRiskScoreInfoSection
        isManagerView={isManagerView}
        actionData={actionData}
        onSwitchToEventsTab={onSwitchToEventsTab}
        onSwitchToActionlogsTab={onSwitchToActionlogsTab}
      />
      {!isManagerView && (
        <SpacingX size="md">
          <DashboardCard
            flex="3"
            title={t("attackFactorCard.title")}
            subtitle={t("attackFactorCard.subtitle")}
            spacing="xs"
            info={<IndividualRiskProfileAttackFactorInfoTooltip />}
          >
            <AttackScoreExplanation
              onSwitchToEventsTab={onSwitchToEventsTab}
              attackData={attackData}
            />
          </DashboardCard>
          {!isBasicMimecastCustomer && (
            <DashboardCard flex="2" title={t("actionLogCard.title")}>
              <ActionLog
                profileId={person_nid}
                onSwitchToActionlogsTab={onSwitchToActionlogsTab}
              />
            </DashboardCard>
          )}
        </SpacingX>
      )}
      {vcDemo && <StatusCheck />}
    </SpacingY>
  )
}
