import type { GlobalConfig } from "./GlobalConfig"

// Keep this object like flat JSON, with no business logic. We are using TS here
// only to get the type checking.
const mimecast: GlobalConfig = {
  FEATURE_FLAG_PROVIDER: "INLINE",
  DASHBOARD_TITLE: "dashboard:humanRiskTitle",
  BEHAVIOR_DESCRIPTOR: "dashboard:humanRiskbehaviourDescriptorTitle",
  BEHAVIOR_BREAKDOWN_TITLE: "dashboard:humanRiskBehaviorBreakdownTitle",
  ENABLE_RECOMMENDATIONS_TO_REDUCE_RISK: false,
  ENABLE_DASHBOARD_INSIGHTS: false,
  ENABLE_BEHAVIOR_TRENDS_OVER_TIME: false,
  ENABLE_ATTACK_FACTOR_DISTRIBUTION: false,
  ENABLE_R3_CREATE: false,
  ENABLE_RAP_ADVANCED_FILTERS: false,
  ENABLE_RAP_BULK_ACTIONS: false,
  ENABLE_IRP_ALL_TRENDS_GRAPH: false,
  ENABLE_IRP_ATTACK_EXPLANATION_CARDS: false,
  ENABLE_RAP_DATA_EXPORT: false,
  ENABLE_ACTION_LOGS_EXPORT: false,
  ENABLE_EVENT_LOGS_EXPORT: false,
  ENABLE_ORG_ATTACK_FACTOR: true,
  RAP_FIELD_EXCLUSIONS: [
    "action_factor",
    "organizational_role",
    "employment_type",
    "work_location_geo",
    "is_active",
    "tenure_status",
  ],
  RAP_SORT_EXCLUSIONS: ["full_name", "email", "tenure_duration"],
  RAP_FILTER_EXCLUSIONS: ["tenure_duration"],
  ACTION_LOG_CONTEXT: "MIMECAST",
  ENABLE_IRP_ACTION_LOG_GROUPING: false,
  ELLIE: false,
  SHOW_LAST_SCORING_DATETIME: false,
  PROVISIONING_SCREEN_MESSAGE: "provisioningMessage",
  REQUIRE_TERMS_AND_CONDITIONS: true,
} as const

export default mimecast
