import styled from "styled-components"

export const ModalWrapper = styled.div`
  [class^="Modalstyles__ModalContainer"] {
    padding: 15px 25px;
    max-height: 90%;
  }

  [class^="Modalstyles__ModalContent"] {
    padding: 0;
    font-size: 16px;
  }

  [class^="Modalstyles__ModalHeader"] {
    padding-bottom: 10px;
    border-bottom: none;
  }

  [class^="Modalstyles__ModalFooter"] {
    border-top: none;
    padding: 20px 0 0 0;
  }
`
