import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  OverlayWrapper,
  ChildrenWrapper,
  Overlay,
} from "./TransparentOverlay.styles"

export function TransparentOverlay({
  children,
  when,
  activationElement,
  childOpacity = "0.5",
  overlayOpacity = "0.5",
  onMouseLeave = () => {},
  overlayStyles = {},
  cursor = "not-allowed",
  onClick = () => {},
}) {
  const [currentOverlayOpacity, setCurrentOverlayOpacity] =
    useState(overlayOpacity)

  if (!when) {
    return children
  }

  return (
    <OverlayWrapper onClick={onClick}>
      <ChildrenWrapper opacity={childOpacity}>{children}</ChildrenWrapper>
      <Overlay
        cursor={cursor}
        style={{
          display: "flex",
          justifyContent: "end",
          ...overlayStyles,
        }}
        opacity={currentOverlayOpacity}
        onMouseEnter={() => {
          setCurrentOverlayOpacity("1")
        }}
        onMouseLeave={() => {
          setCurrentOverlayOpacity(overlayOpacity)
          onMouseLeave()
        }}
      >
        {activationElement}
      </Overlay>
    </OverlayWrapper>
  )
}

TransparentOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  when: PropTypes.bool.isRequired,
  activationElement: PropTypes.node,
  childOpacity: PropTypes.string,
  overlayOpacity: PropTypes.string,
  onMouseLeave: PropTypes.func,
  overlayStyles: PropTypes.object,
}
