import React, { useState, useMemo } from "react"
import { HumanRiskBehaviorsSection } from "./HumanRiskBehaviors"
import { TransparentOverlay } from "@src/components/TransparentOverlay"
import { SpacingY } from "@src/components/SpacingY"
import { SpacingX } from "@src/components/SpacingX"
import { useTranslation } from "react-i18next"
import { LearnMoreButton } from "./LearnMoreButton"
import { IntegrationsHubButtonLink } from "./IntegrationsHubButtonLink"

export function HumanRiskBehaviorsTeaser({
  expandSections = true,
  onLearnMoreClick = () => {},
}) {
  const teaserData = useMemo(
    () => [
      { key: "phishing", prev: 0, current: 0 },
      { key: "malware", prev: 0, current: 0 },
    ],
    [],
  )
  const activeActionsOverride = useMemo(
    () => teaserData.map(({ key }) => key),
    [teaserData],
  )

  const [showOverlay, setShowOverlay] = useState(false)

  const { t } = useTranslation("dashboard")

  return (
    <TransparentOverlay
      when={showOverlay}
      activationElement={
        <div style={{ textAlign: "center" }}>
          <SpacingY size="sm">
            <div style={{ fontSize: "1.5em", fontWeight: "bold" }}>
              {t("teaserText.additionalIntegrationsNeeded")}
            </div>
            <div>{t("teaserText.addIntegrationsPrompt")}</div>
            <SpacingX justify="center">
              <LearnMoreButton onClick={onLearnMoreClick} />
              <IntegrationsHubButtonLink />
            </SpacingX>
          </SpacingY>
        </div>
      }
      onMouseLeave={() => {
        setShowOverlay(false)
      }}
      overlayOpacity="1"
      childOpacity="0.1"
      overlayStyles={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        onMouseEnter={() => {
          setShowOverlay(true)
        }}
      >
        <HumanRiskBehaviorsSection
          actionFactorScoreData={teaserData}
          activeActionsOverride={activeActionsOverride}
          teaserMode={true}
          expandSections={expandSections}
        />
      </div>
    </TransparentOverlay>
  )
}
