import { Modal } from "@src/components/Modal"
import { Button } from "@src/components/MimecastReskin"
import { SpacingX } from "@src/components/SpacingX"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { navigateWithRefresh } from "@src/utils/navigateWithRefresh"

import React from "react"
import { useTranslation, Trans } from "react-i18next"

export function UserGroupInfoModal({
  isOpen,
  onClose = () => {},
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const { t } = useTranslation("dashboard")
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={t("userGroupInfoModal.header")}
      rightButtons={[
        <Button theme="ghost" onClick={onClose} key="cancel">
          {t("userGroupInfoModal.cancelButton")}
        </Button>,
        <Button
          onClick={() => {
            // Not using link, so we can force shell app to detect route change
            navigateWithRefresh(
              `${window.location.origin}/admin/engagement/vision2/settings`,
            )
          }}
          key="settings"
        >
          <SpacingX alignItems="center">
            <div>{t("userGroupInfoModal.goToSettingsButton")}</div>
            <FontAwesomeIcon size="lg" icon={faArrowRight} />
          </SpacingX>
        </Button>,
      ]}
    >
      <Trans
        ns="dashboard"
        i18nKey="userGroupInfoModal.content"
        components={{ strong: <strong /> }}
      />
    </Modal>
  )
}
