import { useMemo } from "react"
import { PredefinedWatchlistId } from "../WatchlistsScreen/useWatchlist"
import { useRules } from "./useRules"
import { useI18nResourceBundle } from "@src/utils/useI18nResourceBundle"

export function useRuleNames(): {
  ruleIdToRuleName: { [key: string]: string }
  loading: boolean
} {
  const watchlistIdToWatchlistName = useI18nResourceBundle("watchlists")
    .predefined as Record<PredefinedWatchlistId, { name: string }>

  const { data: watchlistIdToRuleId, loading } = useRules()

  const ruleIdToRuleName = useMemo(
    () =>
      Object.entries(watchlistIdToWatchlistName).reduce(
        (acc, [watchlistId, { name }]) => {
          // Rule name is the same as the corresponding watchlist name
          if (watchlistIdToRuleId[watchlistId as PredefinedWatchlistId]) {
            acc[watchlistIdToRuleId[watchlistId as PredefinedWatchlistId]] =
              name
          }
          return acc
        },
        {} as { [key: string]: string },
      ),
    [watchlistIdToRuleId, watchlistIdToWatchlistName],
  )
  return { ruleIdToRuleName, loading }
}
