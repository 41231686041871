import React, { useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import {
  HumanRiskBehaviorsItemWrapper,
  HumanRiskBehaviorsLeftBar,
  HumanRiskBehaviorsItemTitleRight,
  HumanRiskBehaviorsItemTitleLeft,
  HumanRiskBehaviorsItemBody,
  HumanRiskBehaviorsItemTitleText,
  HumanRiskBehaviorsItemTitle,
  HumanRiskBehaviorsItemDetails,
  HumanRiskBehaviorsItemDetailsRight,
  HumanRiskBehaviorsItemTitleScoreIcon,
  HumanRiskBehaviorsDropdownButton,
} from "./styles"

import {
  Tooltip,
  Icon,
  Badge,
} from "@elevate_security/elevate-component-library"
import { Button } from "@src/components/MimecastReskin"

import { getIconAndColorByScoreDiff } from "@src/utils/colors"

import { arraySelector } from "@src/utils/dataFilter"
import { TIME_PERIOD_MONTHS } from "../../constants"
import { humanize } from "@src/utils/string"
import { useSelector } from "react-redux"
import { EventBadge } from "./EventBadge"
import {
  ScoreBadge,
  WordScoreBadge,
  TeaserScoreBadge,
} from "@src/components/ScoreBadges"
import { useTranslation, Trans } from "react-i18next"
import { roundUserFacingScore } from "@src/utils/numbers/roundUserFacingScore"
import { formatUserFacingScore } from "@src/utils/numbers/formatUserFacingScore"
import { formatAction } from "./formatAction"
import { HumanRiskBehaviorStatistics } from "./HumanRiskBehaviorStatistics"

const TeaserEventBadge = styled(Badge).attrs({
  theme: "eventsgrey",
  shape: "smallboldround",
})`
  color: transparent;
  text-shadow: 0 0 7px black;
`

export const HumanRiskBehaviorsItem = ({
  id,
  current,
  prev,
  onOpenSideDrawer,
  person_nid,
  scoreType,
  onClickOverride,
  eventCountOverride,
  teaserMode = false,
  expand = false,
  showEventCount = true,
}) => {
  const { t } = useTranslation("dashboard")
  const [isCollapsed, setColl] = React.useState(!expand)
  const currentScore = roundUserFacingScore(current)
  const previousScore = roundUserFacingScore(prev)

  const reputations = useSelector((state) => state.get("reputationsReducer"))

  // Round again to account for any floating point imprecision when subtracting
  const trendValue = roundUserFacingScore(currentScore - previousScore)

  const { icon, color } = getIconAndColorByScoreDiff(trendValue)

  const allowDropdown = !person_nid

  const reviewOnClick = () => {
    onOpenSideDrawer({
      keyFactor: id,
      currentFactorScore: currentScore,
      trendValue,
    })
  }

  // These should be coming from component library instead of being hard-coded
  const COLORS = ["#2E7D32", "#60AD5E", "#FFB400", "#EF6C00", "#C62828"]

  const barColor = teaserMode
    ? "rgb(224, 224, 224)"
    : arraySelector(currentScore, COLORS)

  const onClickEmpty = useCallback(() => {}, [])

  return (
    <HumanRiskBehaviorsItemWrapper
      key={id}
      data-analytics={`human-risk-behavior-${id}`}
    >
      <HumanRiskBehaviorsLeftBar barColor={barColor} />
      <HumanRiskBehaviorsItemBody>
        <HumanRiskBehaviorsItemTitle
          useHandCursor={allowDropdown || onClickOverride}
          onClick={() => {
            if (allowDropdown) {
              setColl(!isCollapsed)
            } else if (onClickOverride) {
              onClickOverride(id)
            }
          }}
        >
          <HumanRiskBehaviorsItemTitleLeft>
            {teaserMode ? (
              <TeaserScoreBadge />
            ) : scoreType === "number" ? (
              <ScoreBadge
                text={formatUserFacingScore(currentScore)}
                shape="largeboldround"
                score={currentScore}
              />
            ) : (
              <WordScoreBadge shape="smallboldround" score={currentScore} />
            )}
            <HumanRiskBehaviorsItemTitleText>
              {
                t(`actionNames.${formatAction(id)}`, {
                  defaultValue: humanize(formatAction(id)),
                }) // Use humanized text as the fallback
              }
            </HumanRiskBehaviorsItemTitleText>
            <HumanRiskBehaviorsItemTitleScoreIcon>
              <Tooltip
                body={getTrendIconTooltip(icon, trendValue)}
                placement="top"
                readOnly
                size="xsm"
              >
                <Icon name={icon} fill={color} />
              </Tooltip>
            </HumanRiskBehaviorsItemTitleScoreIcon>
          </HumanRiskBehaviorsItemTitleLeft>
          <HumanRiskBehaviorsItemTitleRight>
            {teaserMode ? (
              <TeaserEventBadge
                text={`${"X".repeat(Math.floor(Math.random() * 2) + 2)} ${t("eventBadge.events")}`}
              />
            ) : (
              showEventCount && (
                <EventBadge
                  id={id}
                  person_nid={person_nid}
                  months={TIME_PERIOD_MONTHS[reputations.time_period_value]}
                  eventCountOverride={eventCountOverride}
                />
              )
            )}
            {allowDropdown && (
              <HumanRiskBehaviorsDropdownButton
                style={{
                  transform: `rotate(${!isCollapsed ? 0 : -180}deg)`,
                }}
              >
                <Icon name="CaretUp" size="xsm" />
              </HumanRiskBehaviorsDropdownButton>
            )}
          </HumanRiskBehaviorsItemTitleRight>
        </HumanRiskBehaviorsItemTitle>
        {!isCollapsed && (
          <HumanRiskBehaviorsItemDetails>
            <HumanRiskBehaviorStatistics
              passOnClick={
                teaserMode
                  ? onClickEmpty
                  : onClickOverride
                    ? () => onClickOverride(id)
                    : reviewOnClick
              }
              id={id}
              person_nid={person_nid}
              teaserMode={teaserMode}
            />
            {!teaserMode && (
              <HumanRiskBehaviorsItemDetailsRight>
                <Button
                  onClick={
                    onClickOverride ? () => onClickOverride(id) : reviewOnClick
                  }
                >
                  {t("humanRiskBehaviourBreakdown.gridItemButtonLabel")}
                </Button>
              </HumanRiskBehaviorsItemDetailsRight>
            )}
          </HumanRiskBehaviorsItemDetails>
        )}
      </HumanRiskBehaviorsItemBody>
    </HumanRiskBehaviorsItemWrapper>
  )
}

HumanRiskBehaviorsItem.propTypes = {
  id: PropTypes.string.isRequired,
  person_nid: PropTypes.string,
  scoreType: PropTypes.string.isRequired,
  current: PropTypes.number.isRequired,
  prev: PropTypes.number.isRequired,
}

export const getTrendIconTooltip = (icon, trendValue) => {
  switch (icon) {
    case "TrendNeutral":
      return (
        <span>
          <Trans
            i18nKey="trendTooltip.neutral"
            ns="dashboard"
            components={{ strong: <strong /> }}
          />
        </span>
      )
    case "TrendUp":
      return (
        <span>
          <Trans
            i18nKey="trendTooltip.up"
            ns="dashboard"
            values={{ trendValue }}
            components={{ strong: <strong /> }}
          />
        </span>
      )
    case "TrendDown":
      return (
        <span>
          <Trans
            i18nKey="trendTooltip.down"
            ns="dashboard"
            values={{ trendValue }}
            components={{ strong: <strong /> }}
          />
        </span>
      )
    default:
      break
  }
}
