import React, { useState, useMemo, useEffect } from "react"
import { Tabs, Tab } from "@elevate_security/elevate-component-library/"
import { TabsWrapper } from "@src/scenes/ManagerDashboard/scenes/Session/styles"
import OverviewTab from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/OverviewTab"
import TeamTrends from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/TeamTrends"
import { useDispatch, useSelector } from "react-redux"
import { getWhoAMI } from "@src/services/selectors/visionSelectors"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { resetMangerTable } from "@src/scenes/ManagerDashboard/services/redux/actions"

function ManagerDashboardV2({ isManagerView }) {
  const { pathname } = useLocation()
  const isTeamTrend = pathname?.includes("/team-trends") ? 1 : 0
  const [selectedTab, setSelectedTab] = useState(isTeamTrend)
  const TABS = ["Overview", "Team Trends"]
  const whoAmI = useSelector(getWhoAMI)
  const { person_nid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const TabsComponent = useMemo(
    () => [<OverviewTab isManagerView={isManagerView} />, <TeamTrends />],
    [],
  )

  const lowerCase = (value) => {
    return value.toLowerCase().replaceAll(" ", "_")
  }

  if (!whoAmI?.is_manager && !person_nid?.length) {
    history.push("/engagement/vision2")
  }
  useEffect(() => {
    dispatch(resetMangerTable())
  }, [])

  return (
    <TabsWrapper>
      <Tabs
        onChange={(i) => {
          setSelectedTab(i)
        }}
        currentTabIndex={selectedTab}
      >
        {TABS.map((tab) => (
          <Tab key={`tab-${lowerCase(tab)}`} title={tab} />
        ))}
      </Tabs>
      <TabsWrapper>{TabsComponent[selectedTab]}</TabsWrapper>
    </TabsWrapper>
  )
}

export default ManagerDashboardV2
