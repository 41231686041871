import PropTypes from "prop-types"
import React, { memo, useState, useEffect } from "react"
import { Tabs, Tab } from "@src/components/MimecastReskin"

import { getLinesInfoToDrawChart, renderLegend } from "./graph"
import {
  GroupChart,
  AxisLabel,
  TitleLegend,
  Column,
  Row,
  Title,
  GraphTabsPosition,
} from "./styles"
import { useGraph } from "./useGraph"
import ViewBy from "./ViewBy"
import { viewByKeys } from "./ViewBy/constans"

const tabs = ["Monthly", "Quarterly"]

function HistoricalTrend({
  behaviorScores,
  barData,
  showViewBy,
  showTabs,
  onMouseEnter,
  onViewByChange,
  yAxisLabel,
  xAxisLabel,
  keyToBeDashed,
  showHeading,
  changeGraphTabsPosition,
  minMaxValue,
  sortType,
  isInsight,
  yAxisDecimal,
  changeLineFocus,
  lineFOcus,
  performanceFlag,
  showGradeLabel,
  showYAxisLabel,
}) {
  const [viewByState, setViewByState] = useState(viewByKeys[0])
  const [viewBySelection, setViewBySelection] = useState([])

  useEffect(() => {
    onViewByChange(viewByState, viewBySelection)
  }, [viewByState, viewBySelection])

  const hasBehaviorScores =
    !!behaviorScores && !!Object.keys(behaviorScores).length
  const hasBarData = !!barData && !!Object.keys(barData).length

  const behaviorsGraphInfo = getLinesInfoToDrawChart(
    behaviorScores,
    keyToBeDashed,
    sortType,
    isInsight,
  )

  const {
    chartRef,
    disabledLines,
    setDisabledLines,
    hideActionsTaken,
    setHideActionsTaken,
    setTypeGraph,
  } = useGraph({
    behaviorScores,
    barData,
    onMouseEnter,
    keyToBeDashed,
    minMaxValue,
    sortType,
    isInsight,
    yAxisDecimal,
    changeLineFocus,
    lineFOcus,
    performanceFlag,
    showGradeLabel,
  })

  const handleOnChangeTypeGraph = (index) =>
    setTypeGraph(index > 0 ? "quarter" : "monthly")

  if (!hasBehaviorScores && !hasBarData) return null

  return (
    <div style={{ position: "relative" }}>
      {showHeading && (
        <Row
          justifyContent="space-between"
          alignItems="center"
          padding="0 8px 26px 15px"
        >
          <Title>Historical Trend</Title>
        </Row>
      )}
      {(showViewBy || showTabs) && (
        <GraphTabsPosition>
          <Row
            justifyContent="flex-end"
            alignItems="center"
            padding="0 8px 26px 15px"
            className={
              changeGraphTabsPosition
                ? "changeGraphTabsPosition"
                : "filters-row"
            }
          >
            {showViewBy && (
              <ViewBy
                viewByState={viewByState}
                onViewByStateChange={setViewByState}
                selection={viewBySelection}
                onViewBySelectionChange={setViewBySelection}
              />
            )}
            {showTabs && (
              <Tabs onChange={handleOnChangeTypeGraph}>
                {tabs.map((tab) => (
                  <Tab title={`${tab}`} key={tab} />
                ))}
              </Tabs>
            )}
          </Row>
        </GraphTabsPosition>
      )}
      <Row>
        {showYAxisLabel && (
          <Column>
            <AxisLabel
              transform="rotate(180deg)"
              writingMode="vertical-rl"
              textOrientation="mixed"
            >
              {yAxisLabel}
            </AxisLabel>
          </Column>
        )}
        <Column width="100%">
          <GroupChart ref={chartRef} />
          <AxisLabel margin="0  0 0 auto" padding="0 0 0 0">
            {xAxisLabel}
          </AxisLabel>
        </Column>
        {showTabs && (
          <Column minWidth="187px" style={{ paddingLeft: 16 }}>
            <TitleLegend padding="0 0 18px 0">
              Show trend lines for:
            </TitleLegend>
            {renderLegend(
              behaviorsGraphInfo,
              disabledLines,
              setDisabledLines,
              hideActionsTaken,
              setHideActionsTaken,
              changeLineFocus,
              keyToBeDashed,
            )}
          </Column>
        )}
      </Row>
    </div>
  )
}

HistoricalTrend.defaultProps = {
  onMouseEnter: () => null,
  onViewByChange: () => null,
  showViewBy: false,
  showTabs: false,
  graphTitle: "Historical Trend",
  yAxisLabel: "Score Distribution",
  xAxisLabel: "Time",
  keyToBeDashed: "overall",
  showHeading: true,
  changeGraphTabsPosition: false,
  minMaxValue: { min: 0, max: 100 },
  sortType: "",
  isInsight: false,
  yAxisDecimal: false,
  performanceFlag: false,
  showGradeLabel: false,
  showYAxisLabel: true,
}

HistoricalTrend.propTypes = {
  onMouseEnter: PropTypes.func,
  onViewByChange: PropTypes.func,
  behaviorScores: PropTypes.shape({}).isRequired,
  barData: PropTypes.array.isRequired,
  showViewBy: PropTypes.bool,
  showTabs: PropTypes.bool,
  yAxisLabel: PropTypes.string,
  xAxisLabel: PropTypes.string,
  keyToBeDashed: PropTypes.string,
  showHeading: PropTypes.bool,
  changeGraphTabsPosition: PropTypes.bool,
  minMaxValue: PropTypes.object,
  sortType: PropTypes.string,
  isInsight: PropTypes.bool,
  yAxisDecimal: PropTypes.bool,
  showGradeLabel: PropTypes.bool,
  showYAxisLabel: PropTypes.bool,
}

export default memo(HistoricalTrend)
