import React, { useEffect } from "react"
import { createGlobalStyle } from "styled-components"
import { useDispatch } from "react-redux"

import Session from "./scenes/Session"
import Sidebar from "./components/Sidebar"
import { showToast } from "@src/services/redux/toasts/actions"

const FORBIDDEN = "manager-dashboard:forbidden"

const StyleOverrideHack = createGlobalStyle`
  #vision > div {
    position: static !important;
    padding: 0 !important;
  }
`

const ManagerDashboard = ({ location, history }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Register manager-dashboard:forbidden event to handle 403 response
    window.addEventListener(FORBIDDEN, forbiddenEvent)
    // Unregister event upon unmount
    return () => {
      window.removeEventListener(FORBIDDEN, forbiddenEvent)
    }
  }, [])

  // 403 event
  const forbiddenEvent = () => {
    showToast(
      "Sorry, you do not have permission to access the requested page.",
      "error",
    )(dispatch)
    // Redirect to manager's own dashboard
    if (location.pathname.includes("/engagement")) {
      history.push("/engagement/vision2/manager-dashboard")
    } else {
      history.push("/vision2/manager-dashboard")
    }
  }

  return location.pathname.includes("/engagement") ? (
    <Session />
  ) : (
    <div style={{ display: "flex" }}>
      <StyleOverrideHack />
      <Sidebar location={location} history={history} />
      <div
        id="vision-dashboard"
        style={{
          backgroundColor: "white",
          position: "absolute",
          left: "270px",
          height: "100%",
          overflow: "auto",
          right: 0,
          padding: "10px 20px",
        }}
      >
        <Session />
      </div>
    </div>
  )
}

export default ManagerDashboard
